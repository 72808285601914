import styled, { css } from 'styled-components';
import * as React from 'react';

type Overflow = 'hidden' | 'auto' | 'scroll';
type Position = 'absolute' | 'relative';
type Display = 'flex' | 'block' | 'inline-block' | 'inline';
type FlexAlign =
  | 'center'
  | 'start'
  | 'end'
  | 'fex-start'
  | 'flex-end'
  | 'baseline'
  | 'stretch';
type Spacing =
  | string
  | {
      t?: string;
      r?: string;
      b?: string;
      l?: string;
      v?: string;
      h?: string;
      all?: string;
    };
function getSpacing(type: 'padding' | 'margin', spacing: Spacing) {
  if (typeof spacing === 'object') {
    return css`
      ${type}-top: ${spacing.t ?? spacing.v ?? spacing.all};
      ${type}-right: ${spacing.r ?? spacing.h ?? spacing.all};
      ${type}-bottom: ${spacing.b ?? spacing.v ?? spacing.all};
      ${type}-left: ${spacing.l ?? spacing.h ?? spacing.all};
    `;
  }
  return `margin: ${spacing}`;
}

type Props = {
  as?: React.ComponentType<any> | 'span' | 'label';
  overflow?: Overflow;
  alignSelf?: FlexAlign;
  position?: Position;
  display?: Display;
  flexDirection?: 'row' | 'column';
  alignItems?: FlexAlign;
  justifyContent?: FlexAlign;
  margin?: Spacing;
  padding?: Spacing;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
};

export const Box: React.FC<Props> = styled.div<Props>`
  overflow: ${({ overflow }) => overflow};
  align-self: ${({ alignSelf }) => alignSelf};
  position: ${({ position }) => position};
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  ${({ margin }) => margin && getSpacing('margin', margin)};
  ${({ padding }) => padding && getSpacing('padding', padding)};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
  max-width: ${({ maxWidth }) => maxWidth};
  min-height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
`;

import * as React from 'react';
import { IList, IListItem, IUser } from '../../../types';
import { usersApi } from '../../../api/users';
import { listsApi } from '../../../api/lists';
import { listItemsApi } from '../../../api/listItems';

export interface IActivity {
  id: string;
  name: string;
  pathname?: string;
  type: 'user' | 'list' | 'listItem';
  createdAt: Date;
  updatedAt: Date;
  otherFields?: Array<{ key: string; value?: string | number | Date }>;
}

export function useActivity(): { activity: IActivity[] } {
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [lists, setLists] = React.useState<IList[]>([]);
  const [items, setItems] = React.useState<IListItem[]>([]);

  const [activity, setActivity] = React.useState<any>([]);

  const dateCreatedAfter = new Date();
  dateCreatedAfter.setMonth(dateCreatedAfter.getMonth() - 2);

  React.useEffect(() => {
    const query = { body: { createdAt: { $gte: dateCreatedAfter } } };

    usersApi
      .filter(query)
      .then((response) => response.result && setUsers(response.result.data));
    listsApi
      .filter(query)
      .then((response) => response.result && setLists(response.result.data));
    listItemsApi
      .filter(query)
      .then((response) => response.result && setItems(response.result.data));
  }, []);

  function userActivityMapper(user: IUser): IActivity {
    return {
      id: user._id,
      name: `${user.firstName} ${user.lastName}`,
      type: 'user',
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
    };
  }

  function listActivityMapper(list: IList): IActivity {
    return {
      id: list._id,
      name: list.name,
      pathname: '/list/' + list._id,
      type: 'list',
      createdAt: list.createdAt,
      updatedAt: list.updatedAt,
      otherFields: [
        { key: 'Privacy', value: list.privacyLevel },
        { key: 'Color', value: list.color },
      ],
    };
  }

  function listItemActivityMapper(item: IListItem): IActivity {
    return {
      id: item._id,
      name: item.name,
      type: 'listItem',
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      otherFields: [
        { key: 'Removed At', value: item.removedAt },
        { key: 'link', value: item.link },
      ],
    };
  }

  React.useEffect(() => {
    let activities: IActivity[] = [];

    activities = activities.concat(users.map(userActivityMapper));
    activities = activities.concat(lists.map(listActivityMapper));
    activities = activities.concat(items.map(listItemActivityMapper));

    activities.sort((a, b) =>
      new Date(a.createdAt).getDate() > new Date(b.createdAt).getDate() ? -1 : 1
    );

    setActivity(activities);
  }, [users, lists, items]);

  return {
    activity,
  };
}

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { resetPassword, verifyResetPasswordJwt } from '../../api/auth';
import { AxiosError } from 'axios';
import { Input } from '../ui/new/Input';
import { Spacer } from '../ui/new/Spacer';
import { Text } from '../ui/new/Text';
import { Box } from '../ui/new/Box';
import { Panel } from '../ui/new/Panel';
import { ButtonLink } from '../ui/new/ButtonLink';
import { Button } from '../ui/new/Button';

export function ResetPassword() {
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [errors, setErrors] = React.useState<{
    password?: string;
    password2?: string;
    error?: string;
  }>({});
  const [loading, setLoading] = React.useState(false);
  const [verified, setVerified] = React.useState(false);
  const [verifyFailed, setVerifyFailed] = React.useState(false);
  const [passwordReset, setPasswordReset] = React.useState(false);

  const params = useParams<{ jwt: string }>();

  React.useEffect(() => {
    verifyResetPasswordJwt(params.jwt).then(
      () => setVerified(true),
      () => setVerifyFailed(true)
    );
  }, [params.jwt]);

  function onSuccess() {
    setLoading(false);
    setPasswordReset(true);
  }

  function onFail(err: AxiosError) {
    setErrors(err.response?.data);
    setLoading(false);
  }

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    resetPassword({
      token: params.jwt,
      passwords: { password, password2 },
    }).then(onSuccess, onFail);
  }

  function getFormMarkup() {
    if (!verified && !verifyFailed) {
      return 'Verifying...';
    }
    if (passwordReset) {
      return (
        <Box>
          Password reset successfully.{' '}
          <ButtonLink
            to={'/login'}
            variant={'filled'}
            color={'primary'}
            size={'m'}
          >
            Return to log in page.
          </ButtonLink>
        </Box>
      );
    }
    if (verifyFailed) {
      return 'Verification failed. Please restart the Reset Password process.';
    }
    return (
      <form onSubmit={onSubmit}>
        <Input
          size={'m'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          value={password}
          error={errors.password}
          label={'Password'}
          autoFocus={true}
          name={'password'}
          type={'password'}
          autoComplete={'new-password'}
          variant={1}
        />
        <Spacer height={'1rem'} />
        <Input
          size={'m'}
          name={'password2'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword2(e.target.value)
          }
          value={password2}
          error={errors.password2 ?? errors.error}
          label={'Confirm Password'}
          type={'password'}
          autoComplete={'new-password'}
          variant={1}
        />
        <Spacer height={'1rem'} />
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            type={'submit'}
            disabled={loading}
            variant={'filled'}
            color={'primary'}
            size={'m'}
            name={'save-password-button'}
          >
            Save
          </Button>
        </Box>
      </form>
    );
  }

  return (
    <Box margin={{ t: '1rem', h: 'auto' }} maxWidth={'25rem'}>
      <Box as={Panel}>
        <Text variant={'h1'}>Set New Password</Text>
        <Spacer height={'1rem'} />
        {getFormMarkup()}
      </Box>
    </Box>
  );
}

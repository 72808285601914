import * as React from 'react';
import styled from 'styled-components';
import { useUserManager } from './useUserManager';

const UserManagerPage = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const ManageColumn = styled.div`
  max-width: 20rem;
  margin: 0 0.5rem 0.5rem;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid var(--sideBarBorderColor);
  background-color: var(--secondaryBackgroundColor);
`;

const ManageColumnTitle = styled.h1`
  margin-bottom: 1rem;
`;

interface IUserRowProps {
  selected: boolean;
}

const UserRowBase = styled.div<IUserRowProps>`
  margin-bottom: 0.25rem;
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.selected ? props.theme.textColor : props.theme.sideBarBorderColor};
  background-color: var(--backgroundColor);
  color: var(--textColor);
  font-size: 0.8rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const UserRowContainer = styled(UserRowBase)`
  padding: 0.5rem;
`;

const UserRowButton = styled.button`
  padding: 0.5rem;
  width: 100%;
  text-align: left;
  border: none;
  border-radius: 4px;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  font-size: 0.8rem;
`;

const UserRowButtons = styled.div`
  display: flex;
  margin-top: 0.25rem;
`;

interface ISuccessButtonProps {
  success: boolean;
}

const SuccessButton = styled.button<ISuccessButtonProps>`
  flex: 1;
  padding: 0.5rem 0;
  margin-right: 0.25rem;
  color: var(--textColor);
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.success ? 'green' : props.theme.borderColor)};
  font-weight: ${(props) => (props.success ? '700' : 'normal')};
  background-color: ${(props) =>
    props.success
      ? props.theme.greenBackground
      : props.theme.secondaryBackgroundColor};
  font-size: 0.7rem;

  &:last-child {
    margin-right: 0;
  }
`;

export function UserManager() {
  const {
    friendships,
    handleFollowingClick,
    handleFollowerClick,
    users,
    selectedUser,
    handleUserClick,
    resetPasswordEmailSent,
    handleSendResetPasswordEmailClick,
  } = useUserManager();

  return (
    <UserManagerPage>
      <ManageColumn>
        <ManageColumnTitle>Select user</ManageColumnTitle>
        {users.map((user) => {
          return (
            <UserRowBase
              key={user._id}
              selected={selectedUser ? selectedUser._id === user._id : false}
            >
              <UserRowButton id={user._id} onClick={handleUserClick}>
                {user.firstName} {user.lastName}
              </UserRowButton>
            </UserRowBase>
          );
        })}
      </ManageColumn>
      {selectedUser ? (
        <>
          <ManageColumn>
            <ManageColumnTitle>
              Manage {selectedUser.firstName} {selectedUser.lastName}
            </ManageColumnTitle>
            <UserRowContainer selected={false}>
              Send reset password email
              <UserRowButtons>
                <SuccessButton
                  success={resetPasswordEmailSent}
                  onClick={handleSendResetPasswordEmailClick}
                >
                  Send
                </SuccessButton>
              </UserRowButtons>
            </UserRowContainer>
          </ManageColumn>
          <ManageColumn>
            <ManageColumnTitle>Manage users friends</ManageColumnTitle>
            {users.map((user) => {
              const followedBySelectedUser = Boolean(
                friendships.find(
                  (f) =>
                    f.userId === selectedUser?._id && f.friendId === user._id
                )
              );
              const followsSelectedUser = Boolean(
                friendships.find(
                  (f) =>
                    f.userId === user._id && f.friendId === selectedUser._id
                )
              );
              return (
                <UserRowContainer
                  key={user._id}
                  id={user._id}
                  selected={selectedUser._id === user._id}
                >
                  {user.firstName} {user.lastName}
                  <UserRowButtons>
                    <SuccessButton
                      success={followedBySelectedUser}
                      onClick={() =>
                        handleFollowingClick(user, followedBySelectedUser)
                      }
                    >
                      Followed by {selectedUser.firstName}
                    </SuccessButton>
                    <SuccessButton
                      success={followsSelectedUser}
                      onClick={() =>
                        handleFollowerClick(user, followsSelectedUser)
                      }
                    >
                      Follows {selectedUser.firstName}
                    </SuccessButton>
                  </UserRowButtons>
                </UserRowContainer>
              );
            })}
          </ManageColumn>
        </>
      ) : null}
    </UserManagerPage>
  );
}

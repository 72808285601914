import * as React from 'react';
import styled, { css } from 'styled-components';
import { StyledButton } from './Button';
import { StyledIconButton } from './IconButton';

type Props = {
  icon: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const Icon = React.forwardRef<HTMLButtonElement, Props>(function Icon(
  props,
  ref
) {
  return (
    <StyledIcon
      ref={ref}
      as={props.onClick && 'button'}
      onClick={props.onClick}
    >
      {props.icon}
    </StyledIcon>
  );
});

export const StyledIcon = styled.span.attrs(({ onClick }) => ({
  className: 'material-icons-round',
  type: onClick && 'button',
}))`
  box-sizing: border-box;
  pointer-events: none;
  user-select: none;

  ${({ onClick }) =>
    onClick &&
    css`
      appearance: none;
      background: none;
      border: none;
      cursor: pointer;
      pointer-events: auto;

      &:hover {
        color: var(--secondaryTextColor);
      }
    `}

  ${StyledButton} :not(StyledIconButton) & {
    font-size: 1.5em;
  }
`;

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../redux-store/store';
import { createUser } from '../../redux-store/slices/userSlice';
import { CreateUserRequest } from '../../api/users';
import { unwrapResult } from '@reduxjs/toolkit';
import { Input } from '../ui/new/Input';
import { Spacer } from '../ui/new/Spacer';
import { Box } from '../ui/new/Box';
import { ButtonLink } from '../ui/new/ButtonLink';
import { Icon } from '../ui/new/Icon';
import { Panel } from '../ui/new/Panel';
import { Button } from '../ui/new/Button';
import { Text } from '../ui/new/Text';

export function RegisterUser() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [errors, setErrors] = React.useState<
    Partial<CreateUserRequest['body']>
  >({});

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  function onSubmit(event: React.MouseEvent<HTMLFormElement>) {
    event.preventDefault();

    dispatch(
      createUser({
        body: {
          firstName,
          lastName,
          email,
          password,
          password2,
        },
      })
    )
      .then(unwrapResult)
      .then(() => history.push('/login'))
      .catch(errors => setErrors(errors.response.data));
  }

  return (
    <Box
      maxWidth={'23rem'}
      margin={{ h: 'auto', t: '1rem' }}
      padding={{ h: '1rem' }}
    >
      <ButtonLink to={'/'} size={'s'} variant={'outlined'} color={'secondary'}>
        <Icon icon={'keyboard_backspace'} />
        Back
      </ButtonLink>
      <Box margin={'1rem 0'} as={Panel}>
        <Text variant={'h1'}>Create an Account</Text>
        <Spacer height={'1rem'} />
        <form onSubmit={onSubmit}>
          <Input
            size={'m'}
            onChange={event => setFirstName(event.currentTarget.value)}
            name={'firstName'}
            value={firstName}
            label={'First Name'}
            error={errors?.firstName}
            variant={1}
          />
          <Spacer height={'1rem'} />
          <Input
            size={'m'}
            onChange={event => setLastName(event.currentTarget.value)}
            name={'lastName'}
            value={lastName}
            label={'Last Name'}
            error={errors?.lastName}
            variant={1}
          />
          <Spacer height={'1rem'} />
          <Input
            size={'m'}
            onChange={event => setEmail(event.currentTarget.value)}
            name={'email'}
            value={email}
            label={'Email'}
            error={errors?.email}
            type={'email'}
            autoComplete={'username'}
            variant={1}
          />
          <Spacer height={'1rem'} />
          <Input
            size={'m'}
            name={'password'}
            onChange={event => setPassword(event.currentTarget.value)}
            value={password}
            error={errors?.password}
            label={'Password'}
            type={showPassword ? 'text' : 'password'}
            autoComplete={'new-password'}
            rightIcon={showPassword ? 'visibility' : 'visibility_off'}
            onRightIconClick={() => setShowPassword(!showPassword)}
            variant={1}
          />
          <Spacer height={'1rem'} />
          <Input
            size={'m'}
            name={'password2'}
            onChange={event => setPassword2(event.currentTarget.value)}
            value={password2}
            error={errors?.password2}
            label={'Confirm Password'}
            type={showPassword ? 'text' : 'password'}
            autoComplete={'new-password'}
            variant={1}
          />
          <Spacer height={'1rem'} />
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button
              name={'register-button'}
              type={'submit'}
              size={'m'}
              variant={'filled'}
              color={'primary'}
            >
              Create
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

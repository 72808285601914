import * as React from 'react';
import { ListRow } from './ListRow';
import { ContentEditableField } from '../ui/ContentEditableField';
import { AxiosError } from 'axios';
import { IList } from '../../types';
import Button from '../ui/Button';
import { LinkModal } from './list-items/LinkModal';
import styled from 'styled-components';
import { useAppDispatch } from '../../redux-store/store';
import { createListItem } from '../../redux-store/slices/listItemSlice';
import { CreateListItemRequest } from '../../api/listItems';

const AddButton = styled.button`
  background-color: var(--buttonSecondaryColor);
  border-radius: 4px;
  border: none;
  color: var(--listIconsColor);
  transition: all 180ms;
  -webkit-appearance: none;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);

  &:hover {
    background-color: var(--buttonHoverColor);
  }

  &:disabled {
    box-shadow: none;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }
`;

export const NewItemHelpText = styled(ListRow)`
  font-size: 0.65rem;
  color: var(--secondaryTextColor);
  background-color: transparent;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin-left: 2rem;

  .material-icons {
    font-size: inherit;
    margin-right: 0.25rem;
    margin-left: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

type Props = {
  list: IList;
};

export function NewListItemRow(props: Props) {
  const dispatch = useAppDispatch();

  const [name, setName] = React.useState('');
  const [link, setLink] = React.useState('');
  const [showLinkModal, setShowLinkModal] = React.useState(false);

  function onChange(html: string) {
    setName(html);
  }

  function onSuccess() {
    setName('');
    setLink('');
  }

  function onFail(error: AxiosError) {
    console.log(error.response?.data);
  }

  function onSubmit(html: string) {
    const createListItemRequest: CreateListItemRequest = {
      body: {
        name: html,
        listId: props.list._id,
      },
    };
    if (name) {
      createListItemRequest.body.name = html;
    }
    if (link) {
      createListItemRequest.body.link = link;
    }
    if (Boolean(name || link)) {
      dispatch(createListItem(createListItemRequest)).then(onSuccess, onFail);
    }
  }

  return (
    <ListRow listColor={props.list.color}>
      <div className={'list-row-left'}>
        <AddButton
          onClick={() => onSubmit(name)}
          disabled={Boolean(!link && !name)}
        >
          <i className={'material-icons'}>add</i>
        </AddButton>
      </div>
      <div className={'list-row-middle'}>
        <div className={'list-row-middle-top'}>
          <div className={'list-row-title'}>
            <ContentEditableField
              handleChange={onChange}
              placeholder={'List item'}
              html={name}
              onEnterKeyPress={onSubmit}
            />
          </div>
        </div>
      </div>
      <div className={'list-row-right'}>
        <Button
          onClick={() => setShowLinkModal(true)}
          icon={'add'}
          kind={'secondary'}
          size={'small'}
          className={'add-link-button'}
        >
          Link
        </Button>
      </div>
      {showLinkModal ? (
        <LinkModal
          link={link}
          onLinkChange={event => {
            setLink(event.target.value);
          }}
          onSubmit={event => {
            event.preventDefault();
            setShowLinkModal(false);
          }}
          onCancel={() => setShowLinkModal(false)}
        />
      ) : null}
    </ListRow>
  );
}

import * as React from 'react';
import { IFriendship, IUser } from '../../../types';
import { friendshipsApi } from '../../../api/friendships';
import { usersApi } from '../../../api/users';
import { sendResetPasswordEmail } from '../../../api/auth';

export function useUserManager() {
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [friendships, setFriendships] = React.useState<IFriendship[]>([]);

  function getFriendships(user: IUser | undefined) {
    if (user) {
      friendshipsApi
        .filter({
          body: { $or: [{ userId: user._id }, { friendId: user._id }] },
        })
        .then(
          (response) => response.result && setFriendships(response.result.data)
        );
    }
  }

  React.useEffect(() => {
    usersApi
      .filter({})
      .then((response) => response.result && setUsers(response.result.data));
  }, []);

  function removeFriendship(userId: string, friendId: string) {
    const friendshipToDelete = friendships.find((friendship) => {
      return friendship.userId === userId && friendship.friendId === friendId;
    });
    if (friendshipToDelete) {
      friendshipsApi
        .delete({ id: friendshipToDelete._id })
        .then(() => getFriendships(selectedUser))
        .catch((error) => console.log(error));
    }
  }

  function addFriendship(userId: string, friendId: string) {
    friendshipsApi
      .create({ body: { userId, friendId } })
      .then(() => getFriendships(selectedUser))
      .catch((error) => console.log(error));
  }

  function handleFollowingClick(user: IUser, friendshipExists: boolean) {
    if (friendshipExists && selectedUser) {
      removeFriendship(selectedUser._id, user._id);
    } else if (selectedUser) {
      addFriendship(selectedUser._id, user._id);
    }
  }

  function handleFollowerClick(user: IUser, friendshipExists: boolean) {
    if (friendshipExists && selectedUser) {
      removeFriendship(user._id, selectedUser._id);
    } else if (selectedUser) {
      addFriendship(user._id, selectedUser._id);
    }
  }

  const [selectedUser, setSelectedUser] = React.useState<IUser | undefined>();

  function handleUserClick(event: React.MouseEvent<HTMLButtonElement>) {
    const user = users.find((user) => user._id === event.currentTarget.id);
    setSelectedUser(user);
    setResetPasswordEmailSent(false);
    getFriendships(user);
  }

  const [resetPasswordEmailSent, setResetPasswordEmailSent] = React.useState<
    boolean
  >(false);

  function handleSendResetPasswordEmailClick() {
    if (selectedUser) {
      sendResetPasswordEmail({
        email: selectedUser.email,
        url: window.location.origin,
      })
        .then(() => setResetPasswordEmailSent(true))
        .catch((error) => console.log(error));
    }
  }

  return {
    friendships,
    handleFollowingClick,
    handleFollowerClick,
    users,
    selectedUser,
    handleUserClick,
    resetPasswordEmailSent,
    handleSendResetPasswordEmailClick,
  };
}

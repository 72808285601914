import * as React from 'react';
import Modal from '../../ui/modal/Modal';
import Button from '../../ui/Button';
import { IListItem } from '../../../types';
import { Input } from '../../ui/new/Input';
import { Spacer } from '../../ui/new/Spacer';

type Props = {
  link: string;
  onLinkChange: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit: React.MouseEventHandler<HTMLFormElement>;
  onCancel: () => void;
  errors?: Partial<IListItem>;
};

export function LinkModal(props: Props) {
  return (
    <Modal onCancel={props.onCancel}>
      <h1 className="panel-title">Add link</h1>
      <form onSubmit={props.onSubmit}>
        <Input
          onChange={props.onLinkChange}
          name={'link'}
          value={props.link}
          error={props.errors?.link}
          label={'Link'}
          placeholder={'e.g. www.mordor.com'}
          autoComplete={'off'}
          variant={1}
          size={'m'}
        />
        <Spacer height={'1rem'} />
        <Button formButton={true} type={'submit'}>
          Save
        </Button>
      </form>
    </Modal>
  );
}

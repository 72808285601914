import styled from 'styled-components';

type Props =
  | { height: string; width?: never }
  | { height?: never; width: string }
  | { height: string; width: string };

export const Spacer = styled.div<Props>`
  display: ${({ height }) => (height ? 'block' : 'inline-block')};
  height: ${({ height }) => height ?? 0};
  width: ${({ width }) => width ?? 0};
`;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IFriendship } from '../../types';
import { friendshipsApi } from '../../api/friendships';
import { pick } from '../../utils/utils';

const friendshipAdapter = createEntityAdapter<IFriendship>({
  selectId: (friendship) => friendship._id,
});

export const filterFriendships = createAsyncThunk(
  'friendships/filter',
  friendshipsApi.filter
);
export const createFriendship = createAsyncThunk(
  'friendships/create',
  friendshipsApi.create
);
export const editFriendship = createAsyncThunk(
  'friendships/edit',
  friendshipsApi.edit
);
export const getFriendship = createAsyncThunk(
  'friendships/get',
  friendshipsApi.get
);
export const deleteFriendship = createAsyncThunk(
  'friendships/delete',
  friendshipsApi.delete
);

export const friendshipSlice = createSlice({
  name: 'friendships',
  initialState: friendshipAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(filterFriendships.fulfilled, (state, action) => {
        action.payload.result &&
          friendshipAdapter.upsertMany(state, action.payload.result.data);
      })
      .addCase(createFriendship.fulfilled, (state, action) => {
        action.payload.result &&
          friendshipAdapter.addOne(state, action.payload.result.data);
      })
      .addCase(editFriendship.fulfilled, (state, action) => {
        action.payload.result &&
          friendshipAdapter.upsertOne(state, action.payload.result.data);
      })
      .addCase(getFriendship.fulfilled, (state, action) => {
        action.payload.result &&
          friendshipAdapter.upsertOne(state, action.payload.result.data);
      })
      .addCase(deleteFriendship.fulfilled, (state, action) => {
        action.payload.result &&
          friendshipAdapter.removeOne(state, action.payload.result.data._id);
      }),
});

export const friendshipsSelectors = {
  ...friendshipAdapter.getSelectors((state: RootState) => state.friendships),
  makeSelectFollowing: () =>
    createSelector(
      (state: RootState, userId: string) => state.friendships,
      (state: RootState, userId: string) => userId,
      (friendships, userId) => {
        return friendships.ids
          .filter(
            (friendshipId) =>
              friendships.entities[friendshipId]?.userId === userId
          )
          .map((id) => friendships.entities[id])
          .filter(pick);
      }
    ),
  selectFollowingFriendships(state: RootState, userId: string) {
    return state.friendships.ids
      .filter(
        (friendshipId) =>
          state.friendships.entities[friendshipId]?.userId === userId
      )
      .map((id) => state.friendships.entities[id])
      .filter(pick);
  },
  selectFollowerFriendships(state: RootState, userId: string) {
    return state.friendships.ids
      .filter(
        (friendshipId) =>
          state.friendships.entities[friendshipId]?.friendId === userId
      )
      .map((id) => state.friendships.entities[id])
      .filter(pick);
  },
  /**
   * Get all the lists where the person who owns the list
   * is someone who the provided user is following.
   */
  selectFriendLists(state: RootState, userId: string) {
    const followingFriendships = friendshipsSelectors.selectFollowingFriendships(
      state,
      userId
    );
    return state.lists.ids
      .filter((listId) =>
        Boolean(
          followingFriendships.find(
            (friendship) =>
              friendship.friendId === state.lists.entities[listId]?.userId
          )
        )
      )
      .map((id) => state.lists.entities[id])
      .filter(pick);
  },
};

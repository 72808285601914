import * as React from 'react';
import FocusLock from 'react-focus-lock';
import Button from '../Button';

interface Props {
  onCancel: React.MouseEventHandler;
  children: React.ReactNode;
  className?: string;
  includeCloseButton?: boolean;
}

const Modal = ({
  children,
  className = '',
  includeCloseButton = true,
  onCancel,
}: Props) => {
  function handleCancelClick(
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) {
    event.stopPropagation();
    onCancel(event);
  }

  return (
    <FocusLock>
      <div className={'modal-wrapper'} onClick={handleCancelClick}>
        <div
          id={'modal'}
          className={'modal ' + className}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
          {includeCloseButton ? (
            <Button
              icon={'close'}
              className={'close-button'}
              onClick={handleCancelClick}
            />
          ) : null}
        </div>
      </div>
    </FocusLock>
  );
};

export default Modal;

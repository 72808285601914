import { createEndpoint, DefaultError } from './endpoint-manager';

export const sendResetPasswordEmail = createEndpoint<
  { email: string; url: string },
  void
>((client, params) =>
  client.post('/api/reset-password/email', {
    email: params.email,
    url: params.url,
  })
);

export type LogInRequest = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export const logIn = createEndpoint<
  LogInRequest,
  { token: string, refreshToken?: string },
  DefaultError<LogInRequest>
>((client, params) => client.post('/api/auth/login', params));

export const refreshToken = createEndpoint<
  { token: string, refreshToken: string },
  { token: string }
>((client, params) => client.get('/api/auth/refresh-token', { params }));

export const verifyResetPasswordJwt = createEndpoint<string, void>(
  (client, params) =>
    client.post('/api/reset-password/verify', { token: params })
);

export const resetPassword = createEndpoint<
  { token: string; passwords: { password: string; password2: string } },
  void
>((client, params) =>
  client.post('/api/reset-password/set-password', {
    ...params.passwords,
    token: params.token,
  })
);

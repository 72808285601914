import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';
import { ClientResponse, removeAuthToken, setAuthToken } from './index';
import { logOutUser } from "../../redux-store/slices/authSlice";
import { store } from "../../redux-store/store";

export class Client<R, E = unknown> {
  private readonly _axios: AxiosInstance;
  public authToken: string | undefined;

  constructor(axios: AxiosInstance, authToken?: string) {
    this._axios = axios;
    this.authToken = authToken;
  }

  public async get(url: string, options?: AxiosRequestConfig) {
    return this.call('get', url, undefined, options);
  }
  public post(url: string, data: any, options?: AxiosRequestConfig) {
    return this.call('post', url, data, options);
  }
  public put(url: string, data: any, options?: AxiosRequestConfig) {
    return this.call('put', url, data, options);
  }
  public patch(url: string, data: any, options?: AxiosRequestConfig) {
    return this.call('patch', url, data, options);
  }
  public delete(url: string, options?: AxiosRequestConfig) {
    return this.call('delete', url, undefined, options);
  }

  private async call(
    method: Method,
    url: string,
    data?: any,
    options?: AxiosRequestConfig
  ): Promise<ClientResponse<R, E>> {
    try {
      const response: AxiosResponse<R> = await this._axios.request({
        url,
        data,
        method,
        ...options,
      });
      return { result: response, error: null };
    } catch (e) {
      const error = e as AxiosError<E>;
      if (error.response?.status === 401) {
        window.location.reload();
      }
      return { result: null, error };
    }
  }
}

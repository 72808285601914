import * as React from 'react';
import styled from 'styled-components';
import { IList, ListColor } from '../../types';
import { InteractiveDropDown } from '../ui/drop-down/InteractiveDropDown';
import { useAppDispatch } from '../../redux-store/store';
import { editList } from '../../redux-store/slices/listSlice';

const ColorArray = styled.div`
  padding: 0.3rem;
`;

const ColorDot = styled.button<{ color: ListColor; selected: boolean }>`
  margin: 0.25rem 0.25rem 0.25rem 0.34rem;
  padding: 0.5rem;
  border-radius: 2rem;
  border: 2px solid
    ${(props) =>
      props.selected
        ? props.theme.textColor
        : props.theme.listBackgroundColors[props.color]};
  background-color: ${(props) => props.theme.listBackgroundColors[props.color]};

  &:hover {
    border-color: var(--secondaryTextColor);
  }

  &:focus {
    border-color: var(--secondaryTextColor);
    outline: none;
  }
`;

const colors: ListColor[] = [
  'default',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'lightBlue',
  'blue',
  'purple',
  'pink',
  'brown',
  'gray',
];

type Props = {
  list: IList;
};

export function ColorPickerDropDown(props: Props) {
  const dispatch = useAppDispatch();

  const [dropDownOpen, setDropDownOpen] = React.useState<boolean>(false);

  function handleColorClick(event: React.MouseEvent<HTMLButtonElement>) {
    dispatch(
      editList({
        id: props.list._id,
        body: {
          color: colors.includes(event.currentTarget.id as ListColor)
            ? (event.currentTarget.id as ListColor)
            : 'default',
        },
      })
    );
  }

  return (
    <InteractiveDropDown
      onClose={() => setDropDownOpen(false)}
      open={dropDownOpen}
      onDropDownClick={() => setDropDownOpen(!dropDownOpen)}
      buttonClassName={'icon-only-button'}
      icon={'color_lens'}
      kind={'tertiary'}
      align={'right'}
      size={'medium'}
    >
      <ColorArray>
        {colors.map((color) => (
          <ColorDot
            key={color}
            selected={props.list.color === color}
            color={color}
            onClick={handleColorClick}
            id={color}
          />
        ))}
      </ColorArray>
    </InteractiveDropDown>
  );
}

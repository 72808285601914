import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAppSelector } from '../../redux-store/store';
import { AccessLevel } from '../../types';

type Props = {
  component: React.FC;
  blockAccessTo?: AccessLevel;
} & RouteProps;

export function PrivateRoute({
  component: Component,
  blockAccessTo,
  ...rest
}: Props) {
  const auth = useAppSelector((state) => state.auth);
  const isAuthenticated = Boolean(auth.userId);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && !auth.accessLevel) {
          throw new Error('User must have an access level.');
        }

        if (isAuthenticated && auth.accessLevel !== blockAccessTo) {
          return <Component />;
        }

        if (isAuthenticated && auth.accessLevel === blockAccessTo) {
          return <Redirect to={'/not-found'} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location.pathname },
            }}
          />
        );
      }}
    />
  );
}

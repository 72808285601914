import { IFriendship } from '../types';
import { createDefaultEndpoints } from './endpoint-manager';

type CreateFriendshipRequest = {
  body: {
    userId: string;
    friendId: string;
  };
};

type EditFriendshipRequest = {
  id: string;
  body: {
    userId?: string;
    friendshipId?: string;
  };
};

export const friendshipsApi = createDefaultEndpoints<
  IFriendship,
  CreateFriendshipRequest,
  EditFriendshipRequest
>('friendships');

import * as React from 'react';
import { ButtonProps, StyledButton } from './Button';
import styled from 'styled-components';
import { Icon, StyledIcon } from './Icon';

type Props = {
  name: string;
  icon: string;
  disabled?: boolean;
  variant: ButtonProps['variant'];
  color: ButtonProps['color'];
  size: ButtonProps['size'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

/**
 * @deprecated I want to just be able to use Button for everything.
 * I probably need to get svgs working for that to work.
 */
export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  function Button(props: Props, ref) {
    return (
      <StyledIconButton
        ref={ref}
        name={props.name}
        disabled={props.disabled}
        onClick={props.onClick}
        variant={props.variant}
        size={props.size}
        $color={props.color}
      >
        <Icon icon={props.icon} />
      </StyledIconButton>
    );
  }
);

export const StyledIconButton = styled(StyledButton)`
  height: unset;
  padding: ${({ theme, size }) => theme.iconButtons.sizes[size].padding};

  ${StyledIcon} {
    font-size: ${({ theme, size }) => theme.iconButtons.sizes[size].fontSize};
  }
`;

import * as React from 'react';
import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';

import { AddList } from './AddList';
import { ViewList } from './ViewList';
import { SideBar } from '../ui/SideBar';
import { UserButton } from '../users/UserButton';
import { UserAvatar } from '../users/UserAvatar';
import { IList } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import {
  filterFriendships,
  friendshipsSelectors,
} from '../../redux-store/slices/friendshipSlice';
import {
  editUser,
  filterUsers,
  getUser,
  userSelectors,
} from '../../redux-store/slices/userSlice';
import {
  filterLists,
  listsSelectors,
} from '../../redux-store/slices/listSlice';
import { closeModal, openModal } from '../../redux-store/slices/modalSlice';
import { pick } from '../../utils/utils';
import { authSelectors } from '../../redux-store/slices/authSlice';
import { Button } from '../ui/new/Button';
import { IconButton } from '../ui/new/IconButton';
import { Icon } from '../ui/new/Icon';

const selectFollowing = friendshipsSelectors.makeSelectFollowing();

const headerHeight = 52;
const AVATAR_SIZE = 1.35;

export function ListSideBarPage() {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const authUserId = useAppSelector(authSelectors.selectId);
  const user = useAppSelector(state =>
    authUserId ? userSelectors.selectById(state, authUserId) : null
  );
  const allUsers = useAppSelector(userSelectors.selectEntities);
  const followingFriendships = useAppSelector(state =>
    authUserId ? selectFollowing(state, authUserId) : []
  );
  const userLists = useAppSelector(state =>
    state.lists.ids
      .filter(id => state.lists.entities[id]?.userId === authUserId)
      .map(id => state.lists.entities[id])
      .filter(pick)
  );
  const friendLists = useAppSelector(state =>
    authUserId ? friendshipsSelectors.selectFriendLists(state, authUserId) : []
  );
  const currentList = useAppSelector(state =>
    listsSelectors.selectById(state, params.id)
  );

  React.useEffect(() => {
    if (authUserId) {
      dispatch(filterLists({ body: { userId: authUserId } }));
      dispatch(filterFriendships({ body: { userId: authUserId } }));
      dispatch(getUser({ id: authUserId }));
    }
  }, [authUserId, dispatch]);

  React.useEffect(() => {
    if (followingFriendships.length !== 0) {
      const friendIds = followingFriendships.map(
        friendship => friendship.friendId
      );

      dispatch(filterLists({ body: { userId: friendIds } }));
      dispatch(filterUsers({ body: { _id: friendIds } }));
    }
  }, [followingFriendships, dispatch]);

  function openAddListModal() {
    dispatch(openModal(<AddList onCancel={() => dispatch(closeModal())} />));
  }

  function getCreateNewListRow() {
    return (
      <div style={{ marginLeft: '1rem' }}>
        <Button
          name={'add-first-list-button'}
          variant={'outlined'}
          size={'m'}
          color={'secondary'}
          onClick={openAddListModal}
        >
          <i className={'material-icons-round'}>add_circle_outline</i>
          You have no lists. Create a new one!
        </Button>
      </div>
    );
  }

  function getUserHasNoListsMarkup() {
    return (
      <div className={'choice-rows'}>
        <div className={'choice-row'}>
          <div className={'choice-row-content empty-section-row'}>
            <i className={'material-icons-round'}>block</i>User has no lists
          </div>
        </div>
      </div>
    );
  }

  function getChoiceRowsMarkup(lists: IList[]) {
    return (
      <div className={'choice-rows'}>
        {lists.map(list => (
          <div
            className={classnames('choice-row', {
              chosen: params.id === list._id,
            })}
            key={list._id}
          >
            <Link
              className={'choice-row-content'}
              to={'/lists/' + list._id}
              title={list.name}
            >
              {getListIcon(list)}
              {list.name}
            </Link>
          </div>
        ))}
      </div>
    );
  }

  function getUsersListsMarkup() {
    if (!userLists.length) {
      return getCreateNewListRow();
    }

    return getChoiceRowsMarkup(userLists);
  }

  function getUsersSectionMarkup() {
    if (!user) {
      return null;
    }

    return (
      <div className={'choice-section'}>
        <div className={'section-header'}>
          <h4 className={'section-title'}>
            <UserAvatar user={user} size={AVATAR_SIZE} />
            <UserButton userId={user._id}>Me</UserButton>
          </h4>
          <Button
            name={'add-list-button'}
            variant={'outlined'}
            color={'secondary'}
            size={'s'}
            onClick={openAddListModal}
          >
            <span
              style={{
                fontSize: '0.75rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon icon={'add_circle_outline'} />
            </span>
          </Button>
        </div>
        {getUsersListsMarkup()}
      </div>
    );
  }

  function getFriendsListsMarkup(lists: IList[]) {
    if (!lists.length) {
      return getUserHasNoListsMarkup();
    }

    return getChoiceRowsMarkup(lists);
  }

  function onFavoriteUserClick(event: React.MouseEvent<HTMLButtonElement>) {
    const name = event.currentTarget.name;

    if (user) {
      const favoriteUsers = new Set(user.favoriteUsers);
      if (user.favoriteUsers?.includes(name)) {
        favoriteUsers.delete(name);
      } else {
        favoriteUsers.add(name);
      }
      dispatch(
        editUser({
          id: user._id,
          body: { favoriteUsers: Array.from(favoriteUsers) },
        })
      );
    }
  }

  function getFriendsLists() {
    return followingFriendships
      .map(friendship => {
        return {
          user: allUsers[friendship.friendId],
          lists: friendLists.filter(
            list => list.userId === friendship.friendId
          ),
        };
      })
      .sort((a, b) =>
        `${a.user?.firstName} ${a.user?.lastName}`.localeCompare(
          `${b.user?.firstName} ${b.user?.lastName}`
        )
      )
      .sort(friend => (friend.lists.length === 0 ? -1 : 1))
      .sort(friend =>
        friend.user && user?.favoriteUsers?.includes(friend.user?._id) ? -1 : 1
      );
  }

  function getNotFollowingAnyoneMarkup() {
    return (
      <div className={'empty-sidebar-message'}>
        <i className={'material-icons-round center-icon'}>warning</i>
        You are not following anyone. Search for people to follow to see their
        lists.
      </div>
    );
  }

  function getFriendsSection() {
    if (followingFriendships.length === 0) {
      return getNotFollowingAnyoneMarkup();
    }

    return getFriendsLists().map(friend => {
      const userIsFavorite =
        friend.user && user?.favoriteUsers?.includes(friend.user._id);
      return (
        friend.user && (
          <div key={friend.user._id} className={'choice-section'}>
            <div className={'section-header'}>
              <h4 className={'section-title'}>
                {friend.user && (
                  <UserAvatar user={friend.user} size={AVATAR_SIZE} />
                )}
                {friend.user && (
                  <UserButton userId={friend.user._id}>
                    {friend.user.firstName} {friend.user.lastName}
                  </UserButton>
                )}
              </h4>
              <div className={'section-button'}>
                <IconButton
                  icon={userIsFavorite ? 'star' : 'star_outline'}
                  variant={'text'}
                  color={'gold'}
                  size={'s'}
                  onClick={onFavoriteUserClick}
                  name={friend.user._id}
                />
              </div>
            </div>
            {getFriendsListsMarkup(friend.lists)}
          </div>
        )
      );
    });
  }

  function getListIcon(list: IList) {
    if (list.privacyLevel === 'private') {
      return <i className={'material-icons-outlined'}>lock</i>;
    }

    return <i className={'material-icons-round'}>list_alt</i>;
  }

  function getMainContent() {
    if (!params.id || !currentList) {
      return null;
    }

    return <ViewList list={currentList} />;
  }

  return (
    <>
      <SideBar
        sideBarContent={
          <>
            {getUsersSectionMarkup()}
            {getFriendsSection()}
          </>
        }
        mainContent={getMainContent()}
        expanded={!params.id ?? !currentList}
        offsetTop={headerHeight}
      />
    </>
  );
}

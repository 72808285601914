import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  kind: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  type: PropTypes.string,
  formButton: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
  autoFocus: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};

const defaultProps = {
  size: 'medium',
  kind: 'primary',
  type: 'button',
  loading: false,
};

const Button = (props) => {
  function getIconMarkup() {
    if (props.icon) {
      return <i className="material-icons-round button-icon">{props.icon}</i>;
    }
  }

  function getContent() {
    if (props.loading) {
      return 'Loading...';
    }
    return (
      <>
        {getIconMarkup()} {props.children}
      </>
    );
  }

  function getWidth() {
    if (props.width) {
      return {
        width: props.width,
        paddingLeft: 'unset',
        paddingRight: 'unset',
      };
    }
  }

  const {
    size,
    kind,
    formButton,
    className,
    icon,
    width,
    loading,
    ...buttonProps
  } = props;
  return (
    <button
      className={classnames(
        'button',
        size,
        kind,
        {
          'form-button': formButton,
          icon: icon,
          'icon-no-content': icon && !props.children,
        },
        className
      )}
      style={getWidth()}
      disabled={loading}
      {...buttonProps}
    >
      {getContent()}
    </button>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
export default Button;

import * as React from 'react';
import Modal from '../../ui/modal/Modal';
import Button from '../../ui/Button';
import { IListItem } from '../../../types';
import { Input } from '../../ui/new/Input';
import { Spacer } from '../../ui/new/Spacer';

type Props = {
  name: string;
  link?: string;
  onNameChange: React.ChangeEventHandler<HTMLInputElement>;
  onLinkChange: React.ChangeEventHandler<HTMLInputElement>;
  onCancel: () => void;
  onSubmit: React.FormEventHandler;
  errors: Partial<IListItem>;
  title: string;
  submitButtonText?: string;
};

export function ListItemModal(props: Props) {
  return (
    <Modal onCancel={props.onCancel}>
      <h1 className="panel-title">{props.title}</h1>
      <form onSubmit={props.onSubmit}>
        <Input
          onChange={props.onNameChange}
          name={'name'}
          variant={1}
          value={props.name}
          error={props.errors.name}
          label={'Name'}
          placeholder={'e.g. The one ring to rule them all'}
          autoComplete={'off'}
          size={'m'}
        />
        <Spacer height={'1rem'} />
        <Input
          onChange={props.onLinkChange}
          name={'link'}
          variant={1}
          value={props.link ?? ''}
          error={props.errors.link}
          label={'Link'}
          placeholder={'e.g. www.mordor.com'}
          autoComplete={'off'}
          size={'m'}
        />
        <Spacer height={'1rem'} />
        <Button onClick={props.onSubmit} formButton={true} type={'submit'}>
          {props.submitButtonText ?? 'Save'}
        </Button>
      </form>
    </Modal>
  );
}

import { CSSProperties, useCallback, useState } from 'react';
import { useWindowEvent } from '../../../../hooks/useWindowEvent';
import { usePopper } from 'react-popper';

export type UseMenu = {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  toggle: () => void;
  activatorRef: HTMLButtonElement | null;
  setActivatorRef: (el: HTMLButtonElement) => void;
  itemsRef: HTMLUListElement | null;
  setItemsRef: (el: HTMLUListElement) => void;
  itemsStyles: Record<string, CSSProperties>;
  itemsAttributes: any;
};

type Params = {
  placement: 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end';
};

export function useMenu(params?: Params): UseMenu {
  const [expanded, setExpanded] = useState(false);
  const [activatorRef, setActivatorRef] = useState<HTMLButtonElement | null>(
    null
  );
  const [itemsRef, setItemsRef] = useState<HTMLUListElement | null>(null);
  const toggle = useCallback(() => setExpanded(!expanded), [expanded]);
  const { styles, attributes } = usePopper(activatorRef, itemsRef, {
    placement: params?.placement ?? 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useWindowEvent('mousedown', (event) => {
    const target = event.target as HTMLElement;
    if (!itemsRef?.contains(target) && !activatorRef?.contains(target)) {
      setExpanded(false);
    }
  });

  useWindowEvent('keydown', (event) => {
    if (event.key === 'Escape') {
      setExpanded(false);
      activatorRef?.focus();
    }
  });

  return {
    expanded,
    setExpanded,
    toggle,
    activatorRef,
    setActivatorRef,
    itemsRef,
    setItemsRef,
    itemsStyles: styles,
    itemsAttributes: attributes,
  };
}

import * as React from 'react';
import styled from 'styled-components';
import { useActivity } from './useActivity';

const ActivityPage = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const ActivityPanel = styled.div`
  width: 20rem;
  margin: 0 0.5rem 0.5rem;
  padding: 1rem;
  font-size: 0.8rem;
  border-radius: 12px;
  border: 1px solid var(--sideBarBorderColor);
  background-color: var(--secondaryBackgroundColor);
`;

const PanelTitle = styled.h1`
  margin-bottom: 1rem;
`;

const Row = styled.div`
  margin: 0.25rem 0;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--sideBarBorderColor);
  background-color: var(--backgroundColor);
  color: var(--textColor);
  font-size: 0.8rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const typeLabels = {
  user: 'User',
  list: 'List',
  listItem: 'List item',
};

export function Activity() {
  const { activity } = useActivity();
  return (
    <ActivityPage>
      {activity.map((event) => (
        <ActivityPanel key={event.id}>
          Type
          <Row>{typeLabels[event.type]}</Row>
          Name
          <Row>{event.name}</Row>
          Created at
          <Row>{event.createdAt}</Row>
          {event.otherFields?.map((field) => {
            if (field.value) {
              return (
                <>
                  {field.key}
                  <Row>{field.value}</Row>
                </>
              );
            }
          })}
        </ActivityPanel>
      ))}
    </ActivityPage>
  );
}

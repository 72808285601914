import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { authSelectors, logInUser } from '../../redux-store/slices/authSlice';
import { Input } from '../ui/new/Input';
import { Spacer } from '../ui/new/Spacer';
import { unwrapResult } from '@reduxjs/toolkit';
import { DefaultError } from '../../api/endpoint-manager';
import { LogInRequest } from '../../api/auth';
import { Button } from '../ui/new/Button';
import { ButtonLink } from '../ui/new/ButtonLink';
import { Icon } from '../ui/new/Icon';
import { Box } from '../ui/new/Box';
import { Panel } from '../ui/new/Panel';
import { Text } from '../ui/new/Text';

export function LogIn() {
  const dispatch = useAppDispatch();
  const authUserId = useAppSelector(authSelectors.selectId);
  const isAuthenticated = Boolean(authUserId);
  const history = useHistory();
  const location = useLocation<{ from?: string }>();

  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [rememberMe, setRememberMe] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<DefaultError<LogInRequest>>();
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    // redirect logged in users to lists page
    if (isAuthenticated) {
      let path = '/lists';
      if (typeof location.state?.from === 'string') {
        path = location.state.from;
      }
      history.push(path);
    }
  }, [history, isAuthenticated, location.state]);

  function onSubmit(event: React.MouseEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    dispatch(logInUser({ email, password, rememberMe }))
      .then(unwrapResult)
      .then(response => {
        if (response.error) {
          setLoading(false);
          setErrors(response.error.response?.data);
        }
      });
  }

  return (
    <Box
      maxWidth={'23rem'}
      margin={{ h: 'auto', t: '1rem' }}
      padding={{ h: '1rem' }}
    >
      <ButtonLink to={'/'} size={'s'} variant={'outlined'} color={'secondary'}>
        <Icon icon={'keyboard_backspace'} />
        Back
      </ButtonLink>
      <Box margin={'1rem 0'} as={Panel}>
        <Text variant={'h1'}>Log in</Text>
        <Spacer height={'1rem'} />
        <form onSubmit={onSubmit}>
          <Input
            onChange={event => setEmail(event.currentTarget.value)}
            value={email}
            label={'Email'}
            name={'email'}
            autoFocus
            autoComplete={'username'}
            variant={1}
            error={errors?.email}
            size={'m'}
          />
          <Spacer height={'1rem'} />
          <Input
            onChange={event => setPassword(event.currentTarget.value)}
            value={password}
            label={'Password'}
            type={showPassword ? 'text' : 'password'}
            name={'password'}
            autoComplete={'current-password'}
            rightIcon={showPassword ? 'visibility' : 'visibility_off'}
            onRightIconClick={() => setShowPassword(!showPassword)}
            variant={1}
            size={'m'}
            error={errors?.password ?? errors?.error}
          />
          <Spacer height={'1rem'} />
          <label>
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={event => setRememberMe(event.target.checked)}
            />
            <Spacer width={'0.5rem'} />
            Remember me on this computer
          </label>
          <Spacer height={'1rem'} />
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button
              name={'log-in-button'}
              type={'submit'}
              disabled={loading}
              size={'m'}
              variant={'filled'}
              color={'primary'}
            >
              Log in
            </Button>
          </Box>
        </form>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <ButtonLink
          to={'/reset-password/enter-email'}
          size={'s'}
          variant={'outlined'}
          color={'secondary'}
        >
          Forgot password?
        </ButtonLink>
      </Box>
    </Box>
  );
}

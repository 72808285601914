import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../ui/new/Button';
import { AddListItem } from './list-items/AddListItem';
import DragAndDropListBody from '../ui/DragAndDropListBody';
import List from '../ui/list/List';
import ListContext from '../ui/list/ListContext';
import ListTitle from '../ui/list/ListTitle';
import { ListBody } from '../ui/list/ListBody';
import { ContentEditableField } from '../ui/ContentEditableField';
import { EditList } from './EditList';
import { copyTextToClipboard, removeAtIndex } from '../../utils/utils';
import { UserListRow } from './UserListRow';
import { FriendListRow } from './FriendListRow';
import { UserButton } from '../users/UserButton';
import { NewItemHelpText, NewListItemRow } from './NewListItemRow';
import { IList, IListItem, ListPrivacyLevels } from '../../types';
import {
  DraggableProvidedDragHandleProps,
  DropResult,
} from 'react-beautiful-dnd';
import { ColorPickerDropDown } from './ColorPickerDropDown';
import { LoadingListRow } from './ListRow';
import { UserAvatar } from '../users/UserAvatar';
import { filterListItems } from '../../redux-store/slices/listItemSlice';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { getUser } from '../../redux-store/slices/userSlice';
import {
  deleteList,
  editList,
  reorderItems,
} from '../../redux-store/slices/listSlice';
import { closeModal, openModal } from '../../redux-store/slices/modalSlice';
import { Icon } from '../ui/new/Icon';
import { Menu } from '../ui/new/menu/Menu';
import { IconButton } from '../ui/new/IconButton';
import { useMenu } from '../ui/new/menu/useMenu';
import { MessageModal } from '../ui/modal/MessageModal';

type Props = {
  list: IList;
};

export function ViewList(props: Props) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { authUserId, user } = useAppSelector(state => {
    return {
      authUserId: state.auth.userId,
      user: state.users.entities[props.list.userId],
    };
  });

  const listItems = useAppSelector((state): IListItem[] => {
    return props.list.listItemOrder
      .map(itemId => state.listItems.entities[itemId])
      .filter((item): item is IListItem => Boolean(item));
  });

  function getInitialLoadingState(): boolean {
    return listItems.length > 0 ? false : props.list.listItemOrder.length > 0;
  }

  const [loadingInitialItems, setLoadingInitialItems] = React.useState<boolean>(
    getInitialLoadingState()
  );

  React.useEffect(() => {
    function setLoadingInitialItemsFalse() {
      setLoadingInitialItems(false);
    }

    dispatch(filterListItems({ body: { _id: props.list.listItemOrder } })).then(
      setLoadingInitialItemsFalse,
      setLoadingInitialItemsFalse
    );
    dispatch(getUser({ id: props.list.userId }));
    setListTitle(props.list.name);
    setLoadingInitialItems(getInitialLoadingState());
  }, [props.list, dispatch]);

  function getUserOwnsList() {
    return authUserId === props.list.userId;
  }

  function handleDeleteClick() {
    dispatch(deleteList({ id: props.list._id })).then(() =>
      history.push('/lists')
    );
  }

  function onDragEnd(context: DropResult) {
    if (
      !context.source ||
      !context.destination ||
      context.source.index === context.destination.index
    ) {
      return;
    }
    dispatch(
      reorderItems({
        listId: props.list._id,
        currentOrder: props.list.listItemOrder,
        sourceIndex: context.source.index,
        destinationIndex: context.destination.index,
      })
    );
  }

  function copyListToClipboard() {
    const listString = listItems
      .map(item => `- ${item.name}${item.link ? ` (${item.link})` : ''}`)
      .join('\n');
    copyTextToClipboard(listString);
  }

  function insertNewItemRow(item: IListItem) {}

  function onItemRemove(item: IListItem) {
    dispatch(
      editList({
        id: props.list._id,
        body: {
          listItemOrder: removeAtIndex(
            props.list.listItemOrder,
            props.list.listItemOrder.indexOf(item._id)
          ),
        },
      })
    );
  }

  function getDraggableRowMarkup(
    item: IListItem,
    isDragging: boolean,
    dragHandleProps: DraggableProvidedDragHandleProps
  ) {
    return (
      <UserListRow
        item={item}
        list={props.list}
        isDragging={isDragging}
        dragHandleProps={dragHandleProps}
        onEnterClick={(_: any, event: React.KeyboardEvent<HTMLDivElement>) => {
          event.currentTarget.blur();
          insertNewItemRow(item);
        }}
        onItemRemove={onItemRemove}
      />
    );
  }

  function getLoadingRows() {
    return props.list.listItemOrder.map((_, index) => (
      <LoadingListRow key={index} />
    ));
  }

  function getFriendsListRowMarkup(item: IListItem) {
    return <FriendListRow item={item} list={props.list} />;
  }

  const [listTitle, setListTitle] = React.useState(props.list.name);

  function onListTitleChange(name: string) {
    setListTitle(name);
  }

  function onListTitleSubmit(name: string) {
    dispatch(editList({ id: props.list._id, body: { name } }));
  }

  const optionsMenu = useMenu();

  function getUserListMarkup() {
    return (
      <List color={props.list.color}>
        <ListContext>
          {user && <UserAvatar user={user} size={1.35} />}
          {user && (
            <UserButton userId={user?._id}>
              <h3 className={'link secondary'}>
                {user?.firstName} {user?.lastName} (you)
              </h3>
            </UserButton>
          )}
        </ListContext>
        <ListTitle>
          {props.list.privacyLevel === ListPrivacyLevels.private ? (
            <i
              className={'material-icons-outlined'}
              title={'Private list'}
              style={{ marginRight: '0.5rem' }}
            >
              lock
            </i>
          ) : null}
          <ContentEditableField
            handleChange={onListTitleChange}
            placeholder={'Untitled'}
            html={listTitle}
            onEnterKeyPress={onListTitleSubmit}
            onBlur={onListTitleSubmit}
          />
        </ListTitle>
        <div className={'list-toolbar'}>
          <div className={'left-buttons'} />
          <div className={'right-buttons'}>
            <ColorPickerDropDown list={props.list} />
            <IconButton
              name={'more options'}
              icon={'more_horiz'}
              size={'m'}
              variant={'outlined'}
              color={'secondary'}
              onClick={optionsMenu.toggle}
              ref={optionsMenu.setActivatorRef}
            />
            <Menu controller={optionsMenu}>
              <Menu.CTA
                name={'settings'}
                onClick={() =>
                  dispatch(
                    openModal(
                      <EditList
                        onCancel={() => dispatch(closeModal())}
                        list={props.list}
                      />
                    )
                  )
                }
              >
                Settings
              </Menu.CTA>
              <Menu.CTA
                name={'copy-list-to-clipboard-button'}
                onClick={copyListToClipboard}
              >
                Copy list to clipboard
              </Menu.CTA>
              <Menu.Divider />
              <Menu.CTA
                name={'delete-list-button'}
                onClick={() =>
                  dispatch(
                    openModal(
                      <MessageModal
                        onSubmit={handleDeleteClick}
                        title={'Confirm Delete'}
                        message={`Are you sure you want to delete '${props.list.name}'? This cannot be undone`}
                        onCancel={() => dispatch(closeModal())}
                      />
                    )
                  )
                }
              >
                Delete list
              </Menu.CTA>
            </Menu>
            <Button
              onClick={() => {
                dispatch(
                  openModal(
                    <AddListItem
                      onCancel={() => dispatch(closeModal())}
                      listId={props.list._id}
                    />
                  )
                );
              }}
              size={'m'}
              variant={'outlined'}
              color={'secondary'}
              name={'new-list-item-button'}
            >
              <Icon icon={'add'} />
              Add Item
            </Button>
          </div>
        </div>
        {loadingInitialItems ? (
          getLoadingRows()
        ) : (
          <DragAndDropListBody
            data={listItems}
            getRowMarkup={getDraggableRowMarkup}
            onDragEnd={onDragEnd}
          >
            <NewListItemRow list={props.list} />
            <NewItemHelpText>
              <i className={'material-icons'}>arrow_upward</i>
              Click to start typing
              <i className={'material-icons'}>keyboard_return</i>
              Enter to save
            </NewItemHelpText>
          </DragAndDropListBody>
        )}
      </List>
    );
  }

  function getFriendListMarkup() {
    return (
      <List color={props.list.color}>
        <ListContext>
          {user && <UserAvatar user={user} size={1.35} />}
          {user && (
            <UserButton userId={user?._id}>
              <h3 className={'link secondary'}>
                {user?.firstName} {user?.lastName}
              </h3>
            </UserButton>
          )}
        </ListContext>
        <ListTitle>{props.list.name}</ListTitle>
        {loadingInitialItems ? (
          getLoadingRows()
        ) : (
          <ListBody
            data={listItems}
            getRowMarkup={getFriendsListRowMarkup}
            noDataMessage={'There are no Items in this list'}
          />
        )}
      </List>
    );
  }

  return getUserOwnsList() ? getUserListMarkup() : getFriendListMarkup();
}

import * as React from 'react';
import styled from 'styled-components';
import { styleDefaults } from '../../theme/themes';

const ListContext = styled.div`
  margin-bottom: 0.9rem;
  padding: 0 ${styleDefaults.listSidePadding};
  display: flex;
  align-items: center;

  @media (max-width: ${styleDefaults.mobileWidth}) {
    padding: 0 1.5rem;
  }
`;

export default ListContext;

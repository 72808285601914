import * as React from 'react';
import styled from 'styled-components';
import { ListColor } from '../../../types';
import { styleDefaults } from '../../theme/themes';

const List = styled.div<{ color: ListColor }>`
  padding: 3rem 0;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  border: 1px solid
    ${(props) => props.theme.listBorderColors[props.color] ?? 'default'};
  background-color: ${(props) =>
    props.theme.listBackgroundColors[props.color ?? 'default']};
  box-shadow: rgba(0, 0, 0, 0.02) 0 2.8px 2.2px,
    rgba(0, 0, 0, 0.027) 1px 6.7px 5.3px, rgba(0, 0, 0, 0.035) 2px 12.5px 10px,
    rgba(0, 0, 0, 0.043) 5px 22.3px 17.9px,
    rgba(0, 0, 0, 0.05) 10px 41.8px 33.4px, rgba(0, 0, 0, 0.07) 20px 100px 80px;

  @media (max-width: ${styleDefaults.mobileWidth}) {
    padding: 1.5rem 0;
  }
`;

export default List;

import * as React from 'react';
import { ListModal } from './ListModal';
import { IList, ListPrivacyLevel, ListPrivacyLevels } from '../../types';
import { AxiosError } from 'axios';
import { editList } from '../../redux-store/slices/listSlice';
import { EditListRequest } from '../../api/lists';
import { useAppDispatch } from '../../redux-store/store';

interface Props {
  list: IList;
  onCancel: () => void;
}

export function EditList(props: Props) {
  const dispatch = useAppDispatch();

  const [name, setName] = React.useState<string>(props.list.name);
  const [privacyLevel, setPrivacyLevel] = React.useState<ListPrivacyLevel>(
    props.list.privacyLevel
  );
  const [errors, setErrors] = React.useState<IList | undefined>();

  function onSuccess() {
    props.onCancel();
  }

  function onFail(error: AxiosError) {
    setErrors(error.response?.data);
  }

  function onSubmit(event: React.MouseEvent<HTMLFormElement>) {
    event.preventDefault();

    const edits: EditListRequest = { id: props.list._id, body: {} };
    if (name !== props.list.name) {
      edits.body.name = name;
    }
    if (privacyLevel !== props.list.privacyLevel) {
      edits.body.privacyLevel = privacyLevel;
    }

    if (Object.keys(edits.body).length === 0) {
      props.onCancel();
    } else {
      dispatch(editList(edits)).then(onSuccess, onFail);
    }
  }

  function handlePrivacyLevelChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setPrivacyLevel(
      event.target.id === ListPrivacyLevels.private
        ? ListPrivacyLevels.private
        : ListPrivacyLevels.public
    );
  }

  return (
    <ListModal
      onSubmit={onSubmit}
      title={'Edit List'}
      privacyLevel={privacyLevel}
      name={name}
      onCancel={props.onCancel}
      onPrivacyLevelChange={handlePrivacyLevelChange}
      onNameChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setName(event.target.value)
      }
      errors={errors}
    />
  );
}

import { ClientResponse } from "./index";
import { Client } from "./Client";
import { EndpointManager } from "./EndpointManager";

export type Handler<P, R, E = unknown> = (
  client: Client<R, E>,
  params: P
) => Promise<ClientResponse<R, E>>;


export class Endpoint<P, R, E = unknown> {
  private readonly _handler: Handler<P, R, E>;
  private readonly _serviceManager: EndpointManager;
  private _authToken: string | undefined = undefined;

  constructor(handler: Handler<P, R, E>, serviceManager: EndpointManager) {
    this._handler = handler;
    this._serviceManager = serviceManager;
  }

  public async call(params: P): Promise<ClientResponse<R, E>> {
    const client = new Client<R, E>(this._serviceManager.getAxios(), this._authToken);
    return this._handler(client, params);
  }

  public setAuthToken(authToken: string) {
    this._authToken = authToken;
  }

  public removeAuthToken() {
    this._authToken = undefined;
  }
}


import * as React from 'react';
import { ListRow } from './ListRow';
import { ContentEditableField } from '../ui/ContentEditableField';
import { EditLinkModal } from './list-items/EditLinkModal';
import Button from '../ui/Button';
import { copyTextToClipboard, getFixedWebAddress } from '../../utils/utils';
import ControlledDropDown from '../ui/drop-down/ControlledDropDown';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { IList, IListItem } from '../../types';
import { editListItem } from '../../redux-store/slices/listItemSlice';
import { openModal } from '../../redux-store/slices/modalSlice';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTimeout } from '../../hooks/useTimeout';

type Props = {
  item: IListItem;
  list: IList;
  isDragging: boolean;
  dragHandleProps: DraggableProvidedDragHandleProps;
  onItemRemove: (item: IListItem) => void;
  onEnterClick: (
    html: string,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
};

export function UserListRow({
  item,
  list,
  isDragging,
  dragHandleProps,
  onItemRemove,
  ...props
}: Props) {
  const dispatch = useAppDispatch();
  const { resetTimeout, stopTimeout } = useTimeout(1000);
  const [itemName, setItemName] = React.useState(item.name);
  const stRef = React.useRef(itemName);

  const currentSavedListItemName = useAppSelector(state => {
    return state.listItems.entities[item._id]?.name ?? 'UnNamed';
  });

  function onRemoveListItemClick(e: React.MouseEvent) {
    e.preventDefault();
    dispatch(
      editListItem({ id: item._id, body: { removedAt: Date.now() } })
    ).then(() => {
      onItemRemove(item);
    });
  }

  function openLinkModal() {
    dispatch(openModal(<EditLinkModal item={item} />));
  }

  function getLinkButton() {
    if (item.link) {
      return (
        <div className={'double-button'}>
          <a
            href={getFixedWebAddress(item.link)}
            className={'button secondary small main-button'}
            target={'_blank'}
            rel={'noopener noreferrer'}
            title={getFixedWebAddress(item.link)}
          >
            Link
          </a>
          <ControlledDropDown
            size={'small'}
            caret
            align={'right'}
            kind={'secondary'}
            buttonClassName={'secondary-button'}
          >
            <Button
              onClick={() => item.link && copyTextToClipboard(item.link)}
              kind={'secondary'}
              className={'dropdown-content-row'}
            >
              Copy link
            </Button>
            <Button
              onClick={() => openLinkModal()}
              kind={'secondary'}
              className={'dropdown-content-row'}
            >
              Edit link
            </Button>
            <hr />
            <Button
              onClick={() =>
                dispatch(editListItem({ id: item._id, body: { link: '' } }))
              }
              kind={'secondary'}
              className={'dropdown-content-row'}
            >
              Remove link
            </Button>
          </ControlledDropDown>
        </div>
      );
    }
    return (
      <Button
        onClick={() => openLinkModal()}
        icon={'add'}
        kind={'secondary'}
        size={'small'}
        className={'add-link-button'}
      >
        Link
      </Button>
    );
  }

  function getButtons() {
    return (
      <>
        {getLinkButton()}
        <Button
          onClick={onRemoveListItemClick}
          icon={'clear'}
          className={'menu-button'}
          kind={'tertiary'}
          size={'small'}
        />
      </>
    );
  }

  function onItemNameChange(name: string) {
    setItemName(name);
    stRef.current = name;
    resetTimeout(saveItemName);
  }

  function saveItemName() {
    stopTimeout();
    if (!stRef.current) {
      return;
    }
    dispatch(
      editListItem({ id: item._id, body: { name: stRef.current } })
    ).catch(onUpdateNameFail);
  }

  function onUpdateNameFail() {
    setItemName(currentSavedListItemName);
    stRef.current = currentSavedListItemName;
  }

  function onItemNameSubmit() {
    if (itemName !== item.name) {
      dispatch(editListItem({ id: item._id, body: { name: itemName } }));
    }
  }

  return (
    <ListRow id={item._id} isDragging={isDragging} listColor={list.color}>
      <div className={'list-row-left'} {...dragHandleProps}>
        <i className={'material-icons'}>drag_indicator</i>
      </div>
      <div className={'list-row-middle'}>
        <div className={'list-row-middle-top'}>
          <div className={'list-row-title'}>
            <ContentEditableField
              handleChange={onItemNameChange}
              placeholder={'Untitled list item'}
              onEnterKeyPress={(html, event) => {
                onItemNameSubmit();
                props.onEnterClick(html, event);
              }}
              html={itemName}
            />
          </div>
        </div>
      </div>
      <div className={'list-row-right'}>{getButtons()}</div>
    </ListRow>
  );
}

export function copyTextToClipboard(text: string) {
  const fakeInput = document.createElement('textarea');
  fakeInput.style.position = 'absolute';
  fakeInput.style.top = '-100000px';
  document.body.appendChild(fakeInput);
  fakeInput.value = text;
  fakeInput.select();
  fakeInput.setSelectionRange(0, 99999); /*For mobile devices*/
  document.execCommand('copy');
  document.body.removeChild(fakeInput);
}

export function removeAtIndex<T>(list: T[], index: number): T[] {
  return [...list.slice(0, index), ...list.slice(index + 1)];
}

export function insertAtIndex<T>(list: T[], item: T, index: number): T[] {
  return [...list.slice(0, index), item, ...list.slice(index)];
}

export function getNewOrder<T>(
  currentOrder: T[],
  sourceIndex: number,
  destinationIndex: number
) {
  const removedOrder = removeAtIndex(currentOrder, sourceIndex);
  return insertAtIndex(
    removedOrder,
    currentOrder[sourceIndex],
    destinationIndex
  );
}

export function getFixedWebAddress(link?: string): string | undefined {
  if (!link) {
    return;
  } else if (!link.startsWith('http')) {
    return 'http://' + link;
  }
  return link;
}

export function getAvatarUrl(avatarKey: string) {
  return `https://d3mx4wcrg25lgq.cloudfront.net/${avatarKey}`;
}

export function pick<T>(item: T | undefined): item is T {
  return Boolean(item);
}

export type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

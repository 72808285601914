import * as React from 'react';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import { Input } from '../ui/new/Input';
import { Spacer } from '../ui/new/Spacer';
import { Button } from '../ui/new/Button';
import { Box } from '../ui/new/Box';
import { usersApi } from '../../api/users';

const SucceededText = styled.p`
  color: var(--green);
  font-size: 0.7rem;
`;

export function ChangePassword() {
  const [currentPassword, setCurrentPassword] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [errors, setErrors] = React.useState<{
    currentPassword?: string;
    newPassword?: string;
    error?: string;
  }>({});

  const [loading, setLoading] = React.useState<boolean>(false);
  const [succeeded, setSucceeded] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  function onSuccess() {
    setSucceeded(true);
    setErrors({});
    setLoading(false);
  }

  function onFail(errors: AxiosError) {
    setLoading(false);
    setSucceeded(false);
    setErrors(errors.response?.data);
  }

  function onSubmit(event: React.MouseEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    const apiData = {
      currentPassword,
      newPassword,
    };

    usersApi.changePassword(apiData).then(onSuccess).catch(onFail);
  }

  return (
    <form onSubmit={onSubmit}>
      <Input
        size={'m'}
        onChange={(event) => setCurrentPassword(event.currentTarget.value)}
        value={currentPassword}
        error={errors?.currentPassword}
        label={'Current password'}
        name={'current-password'}
        autoComplete={'current-password'}
        type={showPassword ? 'text' : 'password'}
        rightIcon={showPassword ? 'visibility' : 'visibility_off'}
        onRightIconClick={() => setShowPassword(!showPassword)}
        variant={1}
      />
      <Spacer height={'1rem'} />
      <Input
        size={'m'}
        name={'new-password'}
        onChange={(event) => setNewPassword(event.currentTarget.value)}
        value={newPassword}
        error={errors?.newPassword || errors?.error}
        label={'New password'}
        type={showPassword ? 'text' : 'password'}
        autoComplete={'new-password'}
        variant={1}
      />
      {succeeded ? (
        <SucceededText>Successfully changed password</SucceededText>
      ) : null}
      <Spacer height={'1rem'} />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button
          name={'change-password-button'}
          type={'submit'}
          disabled={loading}
          size={'m'}
          variant={'filled'}
          color={'primary'}
        >
          Change
        </Button>
      </Box>
    </form>
  );
}

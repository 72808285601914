import * as React from 'react';

export function useTimeout(time: number) {
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    return timer ? () => clearTimeout(timer) : undefined;
  }, [timer]);

  function stopTimeout() {
    if (timer !== null) {
      clearTimeout(timer);
      setTimer(null);
    }
  }

  function resetTimeout(fn: () => void) {
    if (timer !== null) {
      clearTimeout(timer);
    }
    setTimer(setTimeout(fn, time));
  }

  return {
    resetTimeout,
    stopTimeout,
  };
}

import * as React from 'react';
import Modal from '../ui/modal/Modal';
import Button from '../ui/Button';
import { Radio } from '../ui/Radio';
import { IList, ListPrivacyLevel, ListPrivacyLevels } from '../../types';
import { DefaultError } from '../../api/endpoint-manager';
import { Input } from '../ui/new/Input';
import { Spacer } from '../ui/new/Spacer';

type Props = {
  title: string;
  submitButtonText?: string;
  name: string;
  privacyLevel: ListPrivacyLevel;
  onNameChange: React.ChangeEventHandler<HTMLInputElement>;
  onPrivacyLevelChange: React.ChangeEventHandler<HTMLInputElement>;
  onCancel: () => void;
  onSubmit: React.MouseEventHandler<HTMLFormElement>;
  errors?: DefaultError<IList>;
};

export function ListModal(props: Props) {
  return (
    <Modal onCancel={props.onCancel}>
      <h1 className="panel-title">{props.title}</h1>
      <form onSubmit={props.onSubmit}>
        <Input
          name={'list-name'}
          variant={1}
          onChange={props.onNameChange}
          value={props.name}
          error={props.errors?.name}
          label={'Name'}
          placeholder={'e.g. Christmas list'}
          autoComplete={'off'}
          size={'m'}
        />
        <Spacer height={'1rem'} />
        <Radio
          value={props.privacyLevel}
          onChange={props.onPrivacyLevelChange}
          name={'privacyLevel'}
          options={[ListPrivacyLevels.public, ListPrivacyLevels.private]}
        />
        <Spacer height={'0.5rem'} />
        <Button onClick={props.onSubmit} formButton={true} type={'submit'}>
          {props.submitButtonText ?? 'Save'}
        </Button>
      </form>
    </Modal>
  );
}

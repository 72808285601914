import * as React from 'react';
import { Link } from 'react-router-dom';
import { SearchUsers } from '../users/SearchUsers';
import { ReactComponent as Logo } from '../../assets/images/giftgo.svg';
import throttle from 'lodash/throttle';
import { ThemeContext } from 'styled-components';
import { AccessLevel, Theme } from '../../types';
import { editUser } from '../../redux-store/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { authSelectors, logOutUser } from '../../redux-store/slices/authSlice';
import { Icon } from '../ui/new/Icon';
import { ButtonLink } from '../ui/new/ButtonLink';
import { Menu } from '../ui/new/menu/Menu';
import { useMenu } from '../ui/new/menu/useMenu';
import { Button } from '../ui/new/Button';
import { IconButton } from '../ui/new/IconButton';

export function Navbar() {
  const [skinny, setSkinny] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const authUserId = useAppSelector(authSelectors.selectId);
  const authUserAccess = useAppSelector(authSelectors.selectAccess);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    function updateWidth() {
      const SKINNY_WIDTH = 700;

      if (window.innerWidth < SKINNY_WIDTH && !skinny) {
        setSkinny(true);
      }

      if (window.innerWidth > SKINNY_WIDTH && skinny) {
        setSkinny(false);
      }
    }

    updateWidth();
    const throttledUpdateWidth = throttle(updateWidth, 200);
    window.addEventListener('resize', throttledUpdateWidth);
    return () => {
      window.removeEventListener('resize', throttledUpdateWidth);
    };
  }, [skinny]);

  const theme = React.useContext(ThemeContext);

  function getNewTheme() {
    return theme.key === Theme.dark ? Theme.light : Theme.dark;
  }

  function toggleTheme() {
    authUserId &&
      dispatch(editUser({ id: authUserId, body: { theme: getNewTheme() } }));
  }

  function getLoginMarkup() {
    return (
      <div className="header">
        <Link className={'button medium tertiary'} to="/">
          {/*<Logo title={'giftgo logo'} style={{ width: '1em' }} />*/}
          giftgo.io
        </Link>
        <div className={'right-buttons'}>
          <ButtonLink
            size={'m'}
            variant={'outlined'}
            color={'secondary'}
            to="/login"
          >
            Log in
          </ButtonLink>
          <ButtonLink
            size={'m'}
            variant={'outlined'}
            color={'secondary'}
            to="/register"
          >
            Sign up
          </ButtonLink>
        </div>
      </div>
    );
  }

  const menu = useMenu({ placement: 'bottom-end' });

  function getWideHeaderMarkup() {
    const userIsAdmin = authUserAccess === AccessLevel.admin;
    return (
      <div className="header">
        <Link className={'button medium tertiary home-button'} to="/lists">
          <Logo
            title={'giftgo logo'}
            style={{ width: '1rem', marginRight: '0.5em' }}
          />
          giftgo.io
        </Link>
        <div className={'right-buttons'}>
          <SearchUsers />
          <IconButton
            name={'user-menu-button'}
            variant={'outlined'}
            color={'secondary'}
            size={'m'}
            onClick={menu.toggle}
            ref={menu.setActivatorRef}
            icon={'person'}
          />
          <Menu controller={menu}>
            <Menu.Link name={'lists-link'} to={'/lists'} onClick={menu.toggle}>
              Lists
            </Menu.Link>
            <Menu.Link
              name={'my-profile-link'}
              to={'/users/me'}
              onClick={menu.toggle}
            >
              My profile
            </Menu.Link>
            <Menu.Divider />
            <Menu.CTA name={'toggle-theme'} onClick={toggleTheme}>
              Use {getNewTheme()} mode
            </Menu.CTA>
            <Menu.Divider />
            {userIsAdmin && (
              <>
                <Menu.Link name={'activity-link'} to={'/admin/activity'}>
                  Activity
                </Menu.Link>
                <Menu.Link
                  name={'manage-users-link'}
                  to={'/admin/user-manager'}
                >
                  Manage users
                </Menu.Link>
                <Menu.Divider />
              </>
            )}
            <Menu.CTA
              name={'log-out-cta'}
              onClick={() => dispatch(logOutUser())}
            >
              Log out
            </Menu.CTA>
          </Menu>
        </div>
      </div>
    );
  }

  function handleMenuClick() {
    setExpanded(!expanded);
  }

  function getAccordion() {
    if (expanded) {
      return (
        <ul className="accordion" onClick={handleMenuClick} role={'menu'}>
          <li>
            <Link to="/lists">Lists</Link>
          </li>
          <li className="line">
            <Link to={`/users/me`}>My Profile</Link>
          </li>
          <li>
            <button onClick={toggleTheme}>
              {theme.key === Theme.dark ? '🌞' : '🌚'} Go {getNewTheme()}
            </button>
          </li>
          <li>
            <button
              id="logout"
              onClick={() => {
                dispatch(logOutUser());
              }}
            >
              Log out
            </button>
          </li>
        </ul>
      );
    }
  }

  function getSkinnyHeaderMarkup() {
    return (
      <div className="header skinny">
        <Link className={'button medium tertiary'} to="/lists">
          giftgo.io
        </Link>
        <div className={'right-buttons'}>
          <SearchUsers />
          <Icon icon={'menu'} onClick={handleMenuClick} />
        </div>
        {getAccordion()}
      </div>
    );
  }

  if (!authUserId) {
    return getLoginMarkup();
  }

  if (skinny) {
    return getSkinnyHeaderMarkup();
  }

  return getWideHeaderMarkup();
}

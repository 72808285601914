import * as React from 'react';
import Button from '../Button';
import classnames from 'classnames';
import uniqueId from 'lodash/uniqueId';

export interface IDropDownProps {
  buttonContent?: React.ReactNode;
  dropdownContent?: {
    content?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type?: 'divider';
    id?: string;
  }[];
  align?: 'left' | 'center' | 'right';
  arrow?: boolean;
  caret?: boolean;
  icon?: string;
  open: boolean;
  size?: string;
  width?: string;
  kind?: string;
  onDropDownClick: React.MouseEventHandler<HTMLButtonElement>;
  buttonClassName?: string;
  onRowClick?: React.MouseEventHandler<HTMLButtonElement>;
  dropDownRef?: React.RefObject<HTMLDivElement>;
  children?: React.ReactNode;
}

export function DropDown({
  onRowClick = () => {},
  align = 'left',
  size = 'small',
  ...props
}: IDropDownProps) {
  function onDropdownRowClick(
    onRowClickCallback: React.MouseEventHandler<HTMLButtonElement> = () => {},
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    event.preventDefault();
    onRowClick(event);
    onRowClickCallback(event);
  }

  function getDropdownContentMarkup() {
    let arrowMarkup;
    if (props.arrow) {
      arrowMarkup = (
        <>
          <div className="arrow arrow-behind" />
          <div className="arrow" />
        </>
      );
    }
    if (props.open) {
      return (
        <div
          className={classnames('dropdown-content', `dropdown-align-${align}`, {
            'arrow-dropdown': props.arrow,
          })}
        >
          {arrowMarkup}
          {props.children}
          {props.dropdownContent?.map((row) => {
            if (row.type === 'divider') {
              return <hr key={uniqueId('divider-')} />;
            }
            return (
              <button
                key={row.id || uniqueId('dropdown-row-')}
                id={row.id}
                className={`dropdown-content-row ${size}`}
                onClick={(evt) => onDropdownRowClick(row.onClick, evt)}
              >
                {row.content}
              </button>
            );
          })}
        </div>
      );
    }
  }

  function getCaretMarkup() {
    if (props.caret) {
      return (
        <i className="material-icons dropdown-caret">
          {props.open ? 'arrow_drop_up' : 'arrow_drop_down'}
        </i>
      );
    }
  }

  return (
    <div
      className="dropdown-wrapper"
      style={{ width: props.width }}
      ref={props.dropDownRef}
    >
      <Button
        size={size}
        kind={props.kind}
        width={props.width}
        onClick={props.onDropDownClick}
        icon={props.icon}
        className={props.buttonClassName}
      >
        {props.buttonContent}
        {getCaretMarkup()}
      </Button>
      {getDropdownContentMarkup()}
    </div>
  );
}

export default DropDown;

import * as React from 'react';
import { ButtonLink } from './ui/new/ButtonLink';

export function PageNotFound() {
  return (
    <div className="page-container" style={{ textAlign: 'center' }}>
      <h1 style={{ fontSize: '4em', color: 'var(--secondaryTextColor)' }}>
        404
      </h1>
      <h2 style={{ marginBottom: '1em', fontSize: '3em' }}>Page not found</h2>
      <p style={{ marginBottom: '1em' }}>
        If you believe this occured in error, please contact support at{' '}
        <a
          className={'link primary'}
          href="mailto:giftgofeedback@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          giftgofeedback@gmail.com
        </a>
        .
      </p>
      <ButtonLink size={'l'} variant={'filled'} color={'primary'} to={'/lists'}>
        Home
      </ButtonLink>
    </div>
  );
}

import * as React from 'react';
import debounce from 'lodash/debounce';
import Button from '../ui/Button';
import { OptionField } from '../ui/OptionField';
import { UserAvatar } from './UserAvatar';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { authSelectors } from '../../redux-store/slices/authSlice';
import {
  createFriendship,
  deleteFriendship,
  filterFriendships,
  friendshipsSelectors,
} from '../../redux-store/slices/friendshipSlice';
import { getUser } from '../../redux-store/slices/userSlice';
import { IUser } from '../../types';
import {
  usersApi,
  UserSearchObject,
  UserSearchResponse,
} from '../../api/users';
import { ApiResponse } from '../../api/endpoint-manager';

const SEARCH_TIMEOUT_MILLISECONDS = 300;

export function SearchUsers() {
  const dispatch = useAppDispatch();
  const authUserId = useAppSelector(authSelectors.selectId);
  const currentUserFriendships = useAppSelector((state) =>
    authUserId
      ? friendshipsSelectors.selectFollowingFriendships(state, authUserId)
      : []
  );

  const [searchTerm, setSearchTerm] = React.useState('');
  const [results, setResults] = React.useState<UserSearchResponse>([]);
  const [showResults, setShowResults] = React.useState(false);

  const searchDebounced = React.useCallback(
    debounce(search, SEARCH_TIMEOUT_MILLISECONDS),
    []
  );

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(e.target.value);
    searchDebounced(e.target.value);
  }

  function search(query: string) {
    usersApi.search(query).then(onSearchResponse);
  }

  function onSearchResponse(response: ApiResponse<typeof usersApi.search>) {
    if (response.result) {
      setResults(response.result.data);
      setShowResults(true);
    }
  }

  React.useEffect(() => {
    dispatch(filterFriendships({ body: { userId: authUserId } }));
  }, [authUserId, dispatch]);

  function onFollowClick(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    authUserId &&
      dispatch(
        createFriendship({
          body: {
            userId: authUserId,
            friendId: e.currentTarget.id,
          },
        })
      );
    dispatch(getUser({ id: e.currentTarget.id }));
  }

  function onUnFollowClick(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    dispatch(deleteFriendship({ id: e.currentTarget.id }));
  }

  function getFriendshipButton(user: IUser) {
    const friendship = currentUserFriendships.find(
      (ship) => ship.friendId === user._id
    );
    const userFullName = `${user.firstName} ${user.lastName}`;
    if (friendship) {
      return (
        <Button
          id={friendship._id}
          onClick={onUnFollowClick}
          size={'small'}
          kind={'secondary'}
          title={`UnFollow ${userFullName}`}
        >
          UnFollow
        </Button>
      );
    }
    return (
      <Button
        id={user._id}
        onClick={onFollowClick}
        size={'small'}
        title={`Follow ${userFullName}`}
      >
        Follow
      </Button>
    );
  }

  function getOptionMarkup(result: UserSearchObject) {
    return (
      <div
        key={result.user._id}
        className={'result-row'}
        onClick={() => setShowResults(false)}
      >
        <UserAvatar user={result.user} size={2} />
        <div className={'result-row-text'}>
          <div className={'main-text'}>
            {result.user.firstName} {result.user.lastName}
          </div>
          {result.followedByUsers.length ? (
            <div className={'sub-text'}>
              Followed by{' '}
              {result.followedByUsers
                .map((user) => user.firstName + ' ' + user.lastName[0])
                .join(', ')}
            </div>
          ) : null}
        </div>
        {getFriendshipButton(result.user)}
      </div>
    );
  }

  return (
    <OptionField
      onChange={onChange}
      name={'search'}
      label={'Search Users'}
      hideLabel
      value={searchTerm}
      placeholder={'Find People'}
      autoComplete={'off'}
      rightIcon={searchTerm && 'clear'}
      onRightIconClick={() => setSearchTerm('')}
      leftIcon={'search'}
      getOptionMarkup={getOptionMarkup}
      onClose={() => setShowResults(false)}
      showOptions={showResults}
      options={results}
      variant={2}
      size={'s'}
    />
  );
}

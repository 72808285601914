import * as React from 'react';
import styled, { DefaultTheme } from 'styled-components';

type Props = {
  variant: keyof DefaultTheme['texts']['variants'];
  fontWeight?: 400 | 700 | 900;
};

export const Text: React.FC<Props> = styled.span<Props>`
  font-size: ${({ theme, variant }) => theme.texts.variants[variant].fontSize};
  font-weight: ${({ theme, variant, fontWeight }) =>
    fontWeight ?? theme.texts.variants[variant].fontWeight};
`;

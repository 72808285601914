import * as React from 'react';
import Modal from './Modal';
import { Button } from '../new/Button';
import { useWindowEvent } from '../../../hooks/useWindowEvent';

type Props = {
  title?: string;
  message?: string;
  onCancel: () => void;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  submitButtonText?: string;
  cancelButtonText?: string;
  focusSubmit?: boolean;
};

export function MessageModal(props: Props) {
  useWindowEvent('keydown', (ev) => ev.key === 'Escape' && props.onCancel());

  return (
    <Modal onCancel={props.onCancel}>
      {props.title && <h2 className={'title'}>{props.title}</h2>}
      {props.message && <p className="message">{props.message}</p>}
      <div className="buttons">
        <Button
          name={'cancel-button'}
          onClick={props.onCancel}
          variant={'outlined'}
          color={'secondary'}
          size={'m'}
        >
          {props.cancelButtonText ?? 'Cancel'}
        </Button>
        <Button
          name={'submit-button'}
          onClick={props.onSubmit}
          variant={'filled'}
          color={'primary'}
          size={'m'}
        >
          {props.submitButtonText ?? 'Ok'}
        </Button>
      </div>
    </Modal>
  );
}

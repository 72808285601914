import * as React from 'react';
import Button from '../ui/Button';

type Props = {
  onClose: () => void;
  onBackClick?: () => void;
  middleText?: string;
  children: React.ReactNode;
};

export function PopUpHeader(props: Props) {
  function getBackButton() {
    const buttonProps = {
      onClick: props.onBackClick && props.onBackClick,
      style: props.onBackClick ? undefined : { visibility: 'hidden' },
    };
    return <Button icon={'arrow_back'} kind={'tertiary'} {...buttonProps} />;
  }

  return (
    <div className={'pop-up-header'}>
      <div className={'top'}>
        {getBackButton()}
        <div className={'middle-text'}>{props.middleText}</div>
        <Button onClick={props.onClose} icon={'close'} kind={'tertiary'} />
      </div>
      <div className={'bottom'}>{props.children}</div>
    </div>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import { styleDefaults } from '../../theme/themes';

const ListTitle = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: 700;
  letter-spacing: -0.003em;
  font-size: 2em;
  margin-bottom: 2rem;
  padding: 0 ${styleDefaults.listSidePadding};

  @media (max-width: ${styleDefaults.mobileWidth}) {
    padding: 0 1.5rem;
  }
`;

export default ListTitle;

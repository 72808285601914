import * as React from 'react';
import { ChangePassword } from './ChangePassword';
import { ChangeAvatar } from './ChangeAvatar';
import { UserAvatar } from './UserAvatar';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { authSelectors, logOutUser } from '../../redux-store/slices/authSlice';
import {
  deleteUser,
  editUser,
  getUser,
  userSelectors,
} from '../../redux-store/slices/userSlice';
import { EditUserRequest } from '../../api/users';
import { Input } from '../ui/new/Input';
import { Spacer } from '../ui/new/Spacer';
import { Panel } from '../ui/new/Panel';
import { Box } from '../ui/new/Box';
import { Button } from '../ui/new/Button';
import { Icon } from '../ui/new/Icon';
import { ButtonLink } from '../ui/new/ButtonLink';
import { Text } from '../ui/new/Text';
import { Menu } from '../ui/new/menu/Menu';
import { useMenu } from '../ui/new/menu/useMenu';
import { closeModal, openModal } from '../../redux-store/slices/modalSlice';
import { MessageModal } from '../ui/modal/MessageModal';
import { IconButton } from '../ui/new/IconButton';

export function UserProfile() {
  const dispatch = useAppDispatch();
  const authUserId = useAppSelector(authSelectors.selectId);
  const user = useAppSelector((state) =>
    authUserId ? userSelectors.selectById(state, authUserId) : undefined
  );

  const [firstName, setFirstName] = React.useState(user?.firstName ?? '');
  const [lastName, setLastName] = React.useState(user?.lastName ?? '');
  const [email, setEmail] = React.useState(user?.email ?? '');

  React.useEffect(() => {
    authUserId && dispatch(getUser({ id: authUserId }));
  }, [authUserId, dispatch]);

  React.useEffect(() => {
    setFirstName(user?.firstName ?? '');
    setLastName(user?.lastName ?? '');
    setEmail(user?.email ?? '');
  }, [user]);

  function handleDeleteClick() {
    authUserId &&
      dispatch(deleteUser({ id: authUserId })).then(() =>
        dispatch(logOutUser())
      );
  }

  function handleFormSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (!authUserId) {
      return;
    }

    let editUserRequest: EditUserRequest = { id: authUserId, body: {} };
    if (firstName !== user?.firstName) {
      editUserRequest.body.firstName = firstName;
    }
    if (lastName !== user?.lastName) {
      editUserRequest.body.lastName = lastName;
    }
    if (email !== user?.email) {
      editUserRequest.body.email = email;
    }

    dispatch(editUser(editUserRequest));
  }

  const menu = useMenu();

  return (
    <Box
      maxWidth={'35rem'}
      margin={{ h: 'auto', v: '1rem' }}
      padding={{ h: '1rem' }}
    >
      <ButtonLink to={'/lists'} variant={'outlined'} color={'secondary'} size={'s'}>
        <Icon icon={'arrow_back'} />
        Back
      </ButtonLink>
      <Box display={'flex'} alignItems={'center'} margin={{ t: '1rem' }}>
        {user && <UserAvatar user={user} size={3} />}
        <Text variant={'h1'}>
          {user?.firstName} {user?.lastName}
        </Text>
        <Box margin={{ l: 'auto' }}>
          <IconButton
            name={'options-menu-button'}
            variant={'outlined'}
            color={'secondary'}
            size={'m'}
            onClick={menu.toggle}
            ref={menu.setActivatorRef}
            icon={'more_vert'}
          />
          <Menu controller={menu}>
            <Menu.CTA
              name={'delete-account-button'}
              onClick={() =>
                dispatch(
                  openModal(
                    <MessageModal
                      title={'Confirm delete'}
                      message={
                        'Are you sure you want to delete your account? Once done, this cannot be reversed.'
                      }
                      onCancel={() => dispatch(closeModal())}
                      onSubmit={handleDeleteClick}
                    />
                  )
                )
              }
            >
              Delete account
            </Menu.CTA>
          </Menu>
        </Box>
      </Box>
      <Box as={Panel} margin={{ t: '1rem' }}>
        <Text variant={'h2'}>Change Avatar</Text>
        <Spacer height={'1rem'} />
        {user && <ChangeAvatar user={user} />}
      </Box>
      <Box as={Panel} margin={{ t: '1rem' }}>
        <Text variant={'h2'}>Edit Info</Text>
        <Spacer height={'1rem'} />
        <form onSubmit={handleFormSubmit}>
          <Input
            size={'m'}
            label={'First Name'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFirstName(e.target.value)
            }
            name={'firstName'}
            value={firstName}
            variant={1}
          />
          <Spacer height={'1rem'} />
          <Input
            size={'m'}
            label={'Last Name'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setLastName(e.target.value)
            }
            name={'lastName'}
            value={lastName}
            variant={1}
          />
          <Spacer height={'1rem'} />
          <Input
            size={'m'}
            label={'Email'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            name={'email'}
            value={email}
            variant={1}
          />
          <Spacer height={'1rem'} />
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button
              type={'submit'}
              name={'edit-info-button'}
              size={'m'}
              variant={'filled'}
              color={'primary'}
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
      <Box as={Panel} margin={{ t: '1rem' }}>
        <Text variant={'h2'}>Change Password</Text>
        <Spacer height={'1rem'} />
        <ChangePassword />
      </Box>
    </Box>
  );
}

import * as React from 'react';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import { IUser } from '../../types';
import { UserAvatar } from './UserAvatar';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { authSelectors } from '../../redux-store/slices/authSlice';
import { changeAvatar, editUser } from '../../redux-store/slices/userSlice';
import { Button } from '../ui/new/Button';
import { Box } from '../ui/new/Box';

const Message = styled.p`
  font-size: 0.8rem;
  padding: 1rem 0;
`;

const UploadError = styled(Message)`
  color: red;
`;

const UploadSuccess = styled(Message)`
  color: var(--green);
`;

const FileName = styled.span`
  margin-left: 1rem;
`;

const InputWrapper = styled.div`
  margin: 1.5rem 0 1rem;
`;

type Props = {
  user: IUser;
};

export function ChangeAvatar(props: Props) {
  const dispatch = useAppDispatch();

  const authUserId = useAppSelector(authSelectors.selectId);

  const [refresh, setRefresh] = React.useState(false);
  const fileRef = React.useRef<HTMLInputElement>(null);
  const [errors, setErrors] = React.useState<{ error?: string }>({});
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  function onSuccess() {
    if (fileRef.current) {
      fileRef.current.value = '';
    }
    setErrors({});
    setSuccess(true);
    setLoading(false);
  }

  function onFail(error: AxiosError) {
    setErrors(error.response?.data);
    setLoading(false);
  }

  function handleSubmit(event: React.MouseEvent<HTMLFormElement>) {
    event.preventDefault();
    if (fileRef.current?.files && fileRef.current.files[0]) {
      setSuccess(false);
      setLoading(true);
      const formData = new FormData();
      formData.append('avatar', fileRef.current.files[0]);
      authUserId &&
        dispatch(changeAvatar({ userId: authUserId, formData: formData })).then(
          onSuccess,
          onFail
        );
    } else {
      setSuccess(false);
      setErrors({ error: 'No image selected. Please choose an image.' });
    }
  }

  function removeCurrentAvatar() {
    if (props.user.avatarKey) {
      setLoading(true);
      dispatch(editUser({ id: props.user._id, body: { avatarKey: '' } })).then(
        () => setLoading(false),
        () => setLoading(false)
      );
    } else {
      setErrors({ error: 'You do not have an avatar set' });
      setSuccess(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {props.user.avatarKey ? (
        <Button
          name={'remove-avatar-button'}
          onClick={removeCurrentAvatar}
          color={'secondary'}
          variant={'outlined'}
          size={'m'}
          disabled={loading}
        >
          Remove current avatar:&nbsp;&nbsp;
          <UserAvatar user={props.user} size={1} />
        </Button>
      ) : null}
      <InputWrapper>
        <label className={'button secondary medium'}>
          Choose an image to use as your avatar
          <input
            type={'file'}
            accept={'image/png, image/jpeg'}
            name={'profile-picture'}
            ref={fileRef}
            className={'button secondary small'}
            style={{ display: 'none' }}
            onChange={() => setRefresh(!refresh)}
          />
        </label>
        {fileRef.current?.files && fileRef.current.files[0] ? (
          <FileName>{fileRef.current.files[0].name}</FileName>
        ) : null}
        {errors.error ? <UploadError>{errors.error}</UploadError> : null}
        {success ? (
          <UploadSuccess>Successfully changed avatar</UploadSuccess>
        ) : null}
      </InputWrapper>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button
          name={'change-avatar-button'}
          type={'submit'}
          disabled={loading}
          size={'m'}
          variant={'filled'}
          color={'primary'}
        >
          Set avatar
        </Button>
      </Box>
    </form>
  );
}

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import {
  logIn,
  LogInRequest,
  refreshToken as refreshTokenEndpoint,
} from '../../api/auth';
import { AccessLevel } from '../../types';
import { RootState } from '../store';
import { removeAuthToken, setAuthToken } from "../../api/endpoint-manager";

export const logInUser = createAsyncThunk(
  'auth/logInUser',
  async (userInfo: LogInRequest) => {
    const response = await logIn(userInfo);
    if (response.result) {
      const { token, refreshToken } = response.result.data;
      localStorage.setItem('jwtToken', token);
      setAuthToken(token);
      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
    }
    return response;
  }
);

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (request: { token: string; refreshToken: string }) => {
    const response = await refreshTokenEndpoint(request);
    if (response.result) {
      const token = response.result.data.token;
      localStorage.setItem('jwtToken', token);
      setAuthToken(token);
    }
    return response;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {} as { userId?: string; accessLevel?: AccessLevel },
  reducers: {
    setCurrentUser(
      state,
      action: PayloadAction<{ id: string; accessLevel: AccessLevel }>
    ) {
      state.userId = action.payload.id;
      state.accessLevel = action.payload.accessLevel;
    },
    logOutUser(state) {
      state.userId = undefined;
      state.accessLevel = undefined;
      // Remove token from local storage
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('refreshToken');
      // Remove auth header for future requests
      removeAuthToken();
      // have to do this to refresh redux state for different users
      window.location.reload();
    },
  },
  extraReducers: builder =>
    builder
      .addCase(logInUser.fulfilled, (state, action) => {
        if (action.payload.error) {
          return;
        }
        const decoded = jwt_decode(action.payload.result?.data.token) as {
          id: string;
          accessLevel: AccessLevel;
        };
        state.userId = decoded.id;
        state.accessLevel = decoded.accessLevel;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        if (action.payload.result) return;
        state.userId = undefined;
        state.accessLevel = undefined;
        // Remove token from local storage
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('refreshToken');
        // Remove auth header for future requests
        removeAuthToken();
        // have to do this to refresh redux state for different users
        window.location.reload();
      }),
});

export const { setCurrentUser, logOutUser } = authSlice.actions;

export const authSelectors = {
  selectId(state: RootState) {
    return state.auth.userId;
  },
  selectAccess(state: RootState) {
    return state.auth.accessLevel;
  },
};

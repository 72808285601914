import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IListItem } from '../../types';
import { listItemsApi } from '../../api/listItems';

const listItemAdapter = createEntityAdapter<IListItem>({
  selectId: (listItem) => listItem._id,
});

export const filterListItems = createAsyncThunk(
  'listItems/filter',
  listItemsApi.filter
);
export const createListItem = createAsyncThunk(
  'listItems/create',
  listItemsApi.create
);
export const editListItem = createAsyncThunk(
  'listItems/edit',
  listItemsApi.edit
);
export const getListItem = createAsyncThunk('listItems/get', listItemsApi.get);
export const deleteListItem = createAsyncThunk(
  'listItems/delete',
  listItemsApi.delete
);

export const listItemSlice = createSlice({
  name: 'listItems',
  initialState: listItemAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(filterListItems.fulfilled, (state, action) => {
        action.payload.result &&
          listItemAdapter.upsertMany(state, action.payload.result.data);
      })
      .addCase(createListItem.fulfilled, (state, action) => {
        action.payload.result &&
          listItemAdapter.addOne(state, action.payload.result.data);
      })
      .addCase(editListItem.fulfilled, (state, action) => {
        action.payload.result &&
          listItemAdapter.upsertOne(state, action.payload.result.data);
      })
      .addCase(getListItem.fulfilled, (state, action) => {
        action.payload.result &&
          listItemAdapter.upsertOne(state, action.payload.result.data);
      })
      .addCase(deleteListItem.fulfilled, (state, action) => {
        action.payload.result &&
          listItemAdapter.removeOne(state, action.payload.result.data._id);
      }),
});

export const listItemsSelectors = listItemAdapter.getSelectors(
  (state: RootState) => state.listItems
);

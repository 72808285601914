import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { IUser } from '../../types';
import { usersApi } from '../../api/users';
import { RootState } from '../store';

export const userAdapter = createEntityAdapter<IUser>({
  selectId: (model) => model._id,
});

export const filterUsers = createAsyncThunk('users/filter', usersApi.filter);
export const createUser = createAsyncThunk('users/create', usersApi.create);
export const editUser = createAsyncThunk('users/edit', usersApi.edit);
export const getUser = createAsyncThunk('users/get', usersApi.get);
export const deleteUser = createAsyncThunk('users/delete', usersApi.delete);
export const changeAvatar = createAsyncThunk(
  'users/changeAvatar',
  async (params: { userId: string; formData: FormData }, thunkAPI) => {
    const response = await usersApi.changeAvatar(params.formData);
    return { userId: params.userId, response };
  }
);

export const userSlice = createSlice({
  name: 'users',
  initialState: userAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(filterUsers.fulfilled, (state, action) => {
        action.payload.result &&
          userAdapter.upsertMany(state, action.payload.result.data);
      })
      .addCase(createUser.fulfilled, (state, action) => {
        action.payload.result &&
          userAdapter.addOne(state, action.payload.result.data);
      })
      .addCase(editUser.fulfilled, (state, action) => {
        action.payload.result &&
          userAdapter.upsertOne(state, action.payload.result.data);
      })
      .addCase(getUser.fulfilled, (state, action) => {
        action.payload.result &&
          userAdapter.upsertOne(state, action.payload.result.data);
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        action.payload.result &&
          userAdapter.removeOne(state, action.payload.result.data._id);
      })
      .addCase(changeAvatar.fulfilled, (state, action) => {
        const user = state.entities[action.payload.userId];
        if (action.payload.response.result && user) {
          user.avatarKey = action.payload.response.result.data;
        }
      }),
});

export const userSelectors = userAdapter.getSelectors(
  (state: RootState) => state.users
);

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IList } from '../../types';
import { listsApi } from '../../api/lists';
import { createListItem } from './listItemSlice';
import { getNewOrder } from '../../utils/utils';

const listAdapter = createEntityAdapter<IList>({
  selectId: (list) => list._id,
});

export const filterLists = createAsyncThunk('lists/filter', listsApi.filter);
export const createList = createAsyncThunk('lists/create', listsApi.create);
export const editList = createAsyncThunk('lists/edit', listsApi.edit);
export const getList = createAsyncThunk('lists/get', listsApi.get);
export const deleteList = createAsyncThunk('lists/delete', listsApi.delete);

type ReorderItemsParams = {
  listId: string;
  currentOrder: Array<string>;
  sourceIndex: number;
  destinationIndex: number;
};
export const reorderItems = createAsyncThunk(
  'lists/reorderItems',
  async (params: ReorderItemsParams, thunkAPI) => {
    const newOrder = getNewOrder(
      params.currentOrder,
      params.sourceIndex,
      params.destinationIndex
    );
    thunkAPI.dispatch({
      type: 'lists/reorder',
      payload: { listId: params.listId, newOrder },
    });
    try {
      await listsApi.edit({
        id: params.listId,
        body: {
          listItemOrder: newOrder,
        },
      });
    } catch (err) {
      thunkAPI.dispatch({
        type: 'lists/reorder',
        payload: {
          listId: params.listId,
          newOrder: getNewOrder(
            params.currentOrder,
            params.destinationIndex,
            params.sourceIndex
          ),
        },
      });
      thunkAPI.rejectWithValue(params);
    }
  }
);

export const listSlice = createSlice({
  name: 'lists',
  initialState: listAdapter.getInitialState(),
  reducers: {
    reorder: (
      state,
      action: PayloadAction<{ listId: string; newOrder: Array<string> }>
    ) => {
      const list = state.entities[action.payload.listId];
      if (list) {
        list.listItemOrder = action.payload.newOrder;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(filterLists.fulfilled, (state, action) => {
        action.payload.result &&
          listAdapter.upsertMany(state, action.payload.result.data);
      })
      .addCase(createList.fulfilled, (state, action) => {
        action.payload.result &&
          listAdapter.addOne(state, action.payload.result.data);
      })
      .addCase(editList.fulfilled, (state, action) => {
        action.payload.result &&
          listAdapter.upsertOne(state, action.payload.result.data);
      })
      .addCase(getList.fulfilled, (state, action) => {
        action.payload.result &&
          listAdapter.upsertOne(state, action.payload.result.data);
      })
      .addCase(deleteList.fulfilled, (state, action) => {
        action.payload.result &&
          listAdapter.removeOne(state, action.payload.result.data._id);
      })
      .addCase(createListItem.fulfilled, (state, action) => {
        if (action.payload.result) {
          const list = state.entities[action.payload.result.data.listId];
          list?.listItemOrder.push(action.payload.result.data._id);
        }
      })
      .addCase(reorderItems.pending, (state, action) => {}),
});

export const listsSelectors = listAdapter.getSelectors(
  (state: RootState) => state.lists
);

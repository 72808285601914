import * as React from 'react';
import { IListItem } from '../../../types';

type Props = {
  data: Array<IListItem>;
  getRowMarkup: (item: IListItem) => React.ReactNode;
  onRowClick?: (item: IListItem) => void;
  noDataMessage?: string;
  getRowButtons?: (item: IListItem) => React.ReactNode;
  className?: string;
};

export function ListBody(props: Props) {
  function getBodyMarkup() {
    if (props.data.length === 0) {
      return <p className={'no-data-message'}>{props.noDataMessage}</p>;
    }
    return props.data.map((row) => {
      return (
        <div className={'drag-container'} key={row._id}>
          {props.getRowMarkup(row)}
        </div>
      );
    });
  }

  return (
    <div className={`list-body ${props.className}`}>{getBodyMarkup()}</div>
  );
}

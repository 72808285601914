import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import { Awaited } from '../../utils/utils';
import { Endpoint, Handler } from "./Endpoint";
import { EndpointManager } from "./EndpointManager";

const endpointManager = new EndpointManager();

export function setAuthToken(token: string) {
  endpointManager.setAuthToken(token);
}

export function removeAuthToken() {
  endpointManager.removeAuthToken();
}

export type FilterParams = {
  query?: unknown;
  body?: unknown;
};

type CreateParams = {
  body: unknown;
};

type EditParams = {
  id: string;
  body: unknown;
};

export type GetParams = {
  id: string;
};

export type DeleteParams = {
  id: string;
};

export type DefaultError<T> = Partial<Omit<T, '_id'>> & { error?: string };

export function createDefaultEndpoints<
  TEntity,
  PCreate extends CreateParams,
  PEdit extends EditParams,
  PFilter extends FilterParams = FilterParams
>(name: string) {
  return {
    filter: createEndpoint<PFilter, Array<TEntity>>((client, params) =>
      client.post(`/api/${name}/filter`, params.body, { params: params.query })
    ),
    create: createEndpoint<PCreate, TEntity, DefaultError<TEntity>>(
      (client, params) => client.post(`/api/${name}`, params.body)
    ),
    edit: createEndpoint<PEdit, TEntity, DefaultError<TEntity>>(
      (client, params) => client.patch(`/api/${name}/${params.id}`, params.body)
    ),
    get: createEndpoint<GetParams, TEntity>((client, params) =>
      client.get(`/api/${name}/${params.id}`)
    ),
    delete: createEndpoint<DeleteParams, TEntity>((client, params) =>
      client.delete(`/api/${name}/${params.id}`)
    ),
  } as const;
}

type Result<R, E> = { result: R; error: null } | { result: null; error: E };

export type ClientResponse<R, E = unknown> = Result<
  AxiosResponse<R>,
  AxiosError<E>
>;

export function createEndpoint<P, R, E = unknown>(handler: Handler<P, R, E>) {
  const endpoint = new Endpoint<P, R, E>(handler, endpointManager);
  endpointManager.registerEndpoint(endpoint as Endpoint<unknown, unknown>);
  return (params: P) => endpoint.call(params);
}

export type ApiResponse<T extends (...args: any) => any> = Awaited<
  ReturnType<T>
>;

import axios, {
  AxiosInstance,
} from 'axios';
import { Endpoint } from './Endpoint';

export class EndpointManager {
  private readonly _axios: AxiosInstance;
  private _endpoints: Array<Endpoint<unknown, unknown>>;

  constructor() {
    this._axios = axios.create();
    this._endpoints = [];
  }

  public registerEndpoint(endpoint: Endpoint<unknown, unknown>) {
    this._endpoints.push(endpoint);
  }

  public setAuthToken(authToken: string) {
    this._endpoints.forEach(endpoint => endpoint.setAuthToken(authToken));
    this._axios.defaults.headers.common['Authorization'] = authToken;
  }

  public removeAuthToken() {
    this._endpoints.forEach(endpoint => endpoint.removeAuthToken());
    delete this._axios.defaults.headers.common['Authorization'];
  }

  public getAxios() {
    return this._axios;
  }
}

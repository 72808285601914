import * as React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { colors } from '../../theme/colors';

export type ButtonProps = {
  name: string;
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit';
  variant: 'filled' | 'outlined' | 'text';
  color: keyof DefaultTheme['buttons']['colors'];
  size: keyof DefaultTheme['buttons']['sizes'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    return (
      <StyledButton
        ref={ref}
        name={props.name}
        disabled={props.disabled}
        type={props.type}
        onClick={props.onClick}
        variant={props.variant}
        size={props.size}
        $color={props.color}
      >
        {props.children}
      </StyledButton>
    );
  }
);

type StyledButtonProps = Pick<ButtonProps, 'variant' | 'size'> & {
  $color: ButtonProps['color'];
};

export const StyledButton = styled.button<StyledButtonProps>`
  appearance: none;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;

  height: ${({ theme, size }) => theme.buttons.sizes[size].height};
  padding: 0 ${({ theme, size }) => theme.buttons.sizes[size].padding};
  font-size: ${({ theme, size }) => theme.buttons.sizes[size].fontSize};
  font-weight: 700;
  border-radius: ${({ theme, size }) => theme.buttons.sizes[size].borderRadius};

  ${({ theme, variant, $color: color }) => {
    switch (variant) {
      case 'filled':
        return css`
          color: ${colors.white};
          background-color: ${theme.buttons.colors[color][0]};
          border: 1px solid ${theme.buttons.colors[color][0]};
          box-shadow: ${theme.buttons.colors[color].boxShadow};
        `;
      case 'outlined':
        return css`
          color: ${theme.textColor};
          background-color: ${theme.buttons.colors[color][-1]};
          border: 1px solid ${theme.buttons.colors[color][0]};
          box-shadow: ${theme.buttons.colors[color].boxShadow};
        `;
      case 'text':
        return css`
          color: ${theme.buttons.colors[color][0]};
          background-color: transparent;
          border: none;
        `;
    }
  }};

  @media (hover: hover) {
    &:hover {
      ${({ theme, variant, $color: color }) => {
        switch (variant) {
          case 'filled':
            return css`
              background-color: ${theme.buttons.colors[color][-1]};
              border: 1px solid ${theme.buttons.colors[color][-1]};
            `;
          case 'outlined':
            return css`
              background-color: ${theme.buttons.colors[color][0]};
            `;
          case 'text':
            return css`
              background-color: ${theme.buttons
                .textButtonBackgroundColorHovered};
            `;
        }
      }};
    }
  }

  &:disabled {
    border-color: ${({ theme }) => theme.buttons.disabledBorderColor};
    background-color: ${({ theme }) => theme.buttons.disabledBackgroundColor};
    color: ${({ theme }) => theme.buttons.disabledTextColor};
  }
`;

import * as React from 'react';
import { animated } from 'react-spring';
import { ListRow } from './ListRow';
import Button from '../ui/Button';
import { ClaimButton } from './ClaimButton';
import { IList, IListItem } from '../../types';
import styled from 'styled-components';
import { getFixedWebAddress } from '../../utils/utils';
import { useBoop } from '../../hooks/useBoop';
import { editListItem } from '../../redux-store/slices/listItemSlice';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { authSelectors } from '../../redux-store/slices/authSlice';
import { userSelectors } from '../../redux-store/slices/userSlice';

const ListItemName = styled.a<{ claimed: boolean }>`
  display: flex;
  color: ${(props) =>
    props.claimed ? props.theme.secondaryTextColor : props.theme.textColor};
  text-decoration: none;

  &:hover {
    text-decoration: ${(props) => (props.href ? 'underline' : 'none')};
  }
`;

type Props = {
  item: IListItem;
  list: IList;
};

export function FriendListRow({ item, list }: Props) {
  const authUserId = useAppSelector(authSelectors.selectId);
  const user = useAppSelector(state =>
    authUserId ? userSelectors.selectById(state, authUserId) : null
  );
  const dispatch = useAppDispatch();

  function claim(claimerString: string) {
    if (claimerString) {
      dispatch(
        editListItem({
          id: item._id,
          body: {
            claimerId: authUserId,
            claimerString: claimerString,
          },
        })
      );
    }
  }

  function unClaim() {
    dispatch(
      editListItem({
        id: item._id,
        body: { claimerId: null, claimerString: null },
      })
    );
  }

  function getClaimButton() {
    if (item.claimerId === authUserId) {
      return (
        <>
          {item.claimerString}
          <Button
            onClick={unClaim}
            icon={'clear'}
            className={'menu-button no-hide'}
            kind={'tertiary'}
            size={'small'}
          />
        </>
      );
    } else if (item.claimerId) {
      return item.claimerString;
    }
    return user ? (
      <ClaimButton
        key={'claim-button'}
        onClaim={claim}
        listItemId={item._id}
        initialValue={user.firstName}
      />
    ) : null;
  }

  const [style, trigger] = useBoop({ x: 3, timing: 100 });

  return (
    <ListRow listColor={list.color} onMouseEnter={trigger}>
      <div className={'list-row-left'}>
        <animated.i
          className={'material-icons'}
          title={item.link}
          style={style}
        >
          {item.link ? 'open_in_new' : 'arrow_right_alt'}
        </animated.i>
      </div>
      <div className={'list-row-middle'}>
        <div className={'list-row-middle-top'}>
          <div className={'list-row-title'} style={{ padding: '0.5rem 0' }}>
            <ListItemName
              claimed={Boolean(item.claimerId)}
              href={getFixedWebAddress(item.link)}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              {item.name}
            </ListItemName>
          </div>
        </div>
      </div>
      <div className={'list-row-right'}>{getClaimButton()}</div>
    </ListRow>
  );
}

import * as React from 'react';
import Button from '../ui/Button';
import ButtonDropDown from '../ui/drop-down/ButtonDropDown';
import { Input } from '../ui/new/Input';
import { Spacer } from '../ui/new/Spacer';

type Props = {
  onClaim: (claimerString: string) => void;
  listItemId: string;
  initialValue?: string;
};

export function ClaimButton(props: Props) {
  const [claimerString, setClaimerString] = React.useState(
    props.initialValue ?? ''
  );

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    props.onClaim(claimerString);
  }

  return (
    <span className={'claim-button'}>
      <ButtonDropDown
        align={'right'}
        kind={'secondary'}
        size={'small'}
        buttonContent={'Claim'}
        onButtonClick={() => props.onClaim(claimerString)}
        onDropDownClick={() => null}
      >
        <div className={'claim-dropdown-content'}>
          <form onSubmit={onSubmit}>
            <Input
              name={'claimerString'}
              label={'Claimer name'}
              value={claimerString}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setClaimerString(e.target.value)
              }
              autoFocus
              variant={1}
              size={'m'}
            />
            <Spacer height={'1rem'} />
            <Button onClick={onSubmit} type={'submit'} formButton={true}>
              Claim
            </Button>
          </form>
        </div>
      </ButtonDropDown>
    </span>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modals',
  initialState: { content: null } as { content: React.ReactNode },
  reducers: {
    open(state, action: PayloadAction<React.ReactNode>) {
      state.content = action.payload;
    },
    close(state) {
      state.content = null;
    },
  },
});

export const { open: openModal, close: closeModal } = modalSlice.actions;

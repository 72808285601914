import * as React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

type Props = {
  name: string;
  type?: 'text' | 'number' | 'password' | 'email' | 'url';
  autoComplete?:
    | 'on'
    | 'off'
    | 'password'
    | 'email'
    | 'username'
    | 'new-password'
    | 'current-password';
  autoFocus?: boolean;
  value: string;
  label: string;
  error?: string;
  hideLabel?: boolean;
  placeholder?: string;
  size: keyof DefaultTheme['inputs']['sizes'];
  variant: keyof DefaultTheme['inputs']['variants'];
  leftIcon?: string;
  rightIcon?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onLeftIconClick?: React.MouseEventHandler<HTMLButtonElement>;
  onRightIconClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const Input = React.forwardRef<HTMLInputElement, Props>(function Input(
  props: Props,
  ref
) {
  const inputProps = {
    ref: ref,
    type: props.type,
    'aria-label': props.hideLabel ? props.label : undefined,
    name: props.name,
    value: props.value,
    placeholder: props.placeholder,
    autoComplete: props.autoComplete ?? 'false',
    autoFocus: props.autoFocus,
    leftIcon: props.leftIcon,
    rightIcon: props.rightIcon,
    variant: props.variant,
    inputSize: props.size,
    onChange: props.onChange,
  };

  let inputElement;

  if (props.leftIcon || props.rightIcon) {
    inputElement = (
      <InputWithIconsWrapper hideLabel={props.hideLabel}>
        {props.leftIcon && (
          <InputIcon
            align={'left'}
            as={props.onLeftIconClick && 'button'}
            onClick={props.onLeftIconClick}
          >
            {props.leftIcon}
          </InputIcon>
        )}
        <StyledInput {...inputProps} />
        {props.rightIcon && (
          <InputIcon
            align={'right'}
            as={props.onRightIconClick && 'button'}
            onClick={props.onRightIconClick}
          >
            {props.rightIcon}
          </InputIcon>
        )}
      </InputWithIconsWrapper>
    );
  } else {
    inputElement = <StyledInput {...inputProps} />;
  }

  if (props.hideLabel) {
    return inputElement;
  }

  return (
    <Wrapper>
      <Label>{props.label}</Label>
      {inputElement}
      {props.error && <Error>{props.error}</Error>}
    </Wrapper>
  );
});

type StyledInputProps = Pick<Props, 'leftIcon' | 'rightIcon' | 'variant'> & {
  inputSize: keyof DefaultTheme['inputs']['sizes'];
};

const StyledInput: React.FC<StyledInputProps> = styled.input<StyledInputProps>`
  box-sizing: border-box;
  -webkit-appearance: none;
  width: 100%;
  font-size: ${({ theme, inputSize }) =>
    theme.inputs.sizes[inputSize].fontSize};

  padding: ${({ theme, rightIcon, leftIcon, inputSize }) => {
    const padding = theme.inputs.sizes[inputSize].padding;
    const rightPadding = rightIcon ? '2.35rem' : padding;
    const leftPadding = leftIcon ? '2.35rem' : padding;
    return `${padding} ${rightPadding} ${padding} ${leftPadding}`;
  }};

  color: ${({ theme, variant }) => theme.inputs.variants[variant].textColor};
  background-color: ${({ theme, variant }) =>
    theme.inputs.variants[variant].backgroundColor};
  border: 1px solid
    ${({ theme, variant }) => theme.inputs.variants[variant].borderColor};
  border-radius: 0.5rem;

  ::placeholder {
    ${({ theme, variant }) => theme.inputs.variants[variant].placeholderColor};
  }
`;

const InputIcon = styled.span.attrs(({ onClick }) => ({
  className: 'material-icons-round',
  type: onClick && 'button',
}))<{ align: 'right' | 'left' }>`
  position: absolute;
  ${({ align }) => `${align}: 0;`}
  top: 0;
  height: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ onClick }) =>
    onClick &&
    css`
      appearance: none;
      background: none;
      border: none;
      cursor: pointer;
      pointer-events: auto;

      &:hover {
        color: var(--secondaryTextColor);
      }
    `}
`;

const InputWithIconsWrapper = styled.div<{ hideLabel?: boolean }>`
  position: relative;
  ${({ hideLabel }) => hideLabel && 'width: 100%;'}
`;

const Wrapper = styled.label`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-top: 0.5rem;
`;

const Label = styled.span`
  position: absolute;
  font-size: 0.8rem;
  font-weight: bold;
  display: inline-block;
  z-index: 1;
  padding: 0 0.3rem;
  left: 0.9rem;
  top: 0;
  border-radius: 3px;
  background-color: var(--backgroundColor);
  color: var(--secondaryTextColor);
`;

const Error = styled.span`
  position: absolute;
  color: red;
  font-size: 0.7rem;
  white-space: nowrap;
  bottom: -1rem;
`;

import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '../redux-store/store';
import { Navbar } from './layout/Navbar';
import { LogIn } from './login/LogIn';
import { EnterEmail } from './reset-password/EnterEmail';
import { ResetPassword } from './reset-password/ResetPassword';
import { Landing } from './Landing';
import { PageNotFound } from './PageNotFound';
import { UserProfile } from './users/UserProfile';
import { RegisterUser } from './users/RegisterUser';
import { PrivateRoute } from './private-route/PrivateRoute';
import { ListSideBarPage } from './lists/ListSideBarPage';
import { ModalPortal } from './ui/modal/ModalPortal';
import { UserManager } from './admin/user_manager/UserManager';
import { ThemeProvider } from './theme/ThemeProvider';
import { createGlobalStyle } from 'styled-components';
import { Activity } from './admin/activity/Activity';
import '../assets/stylesheets/app.scss';
import { AccessLevel } from '../types';
import jwtDecode from 'jwt-decode';
import {
  logOutUser,
  refreshToken,
  setCurrentUser,
} from '../redux-store/slices/authSlice';
import { setAuthToken } from '../api/endpoint-manager';

const GlobalStyle = createGlobalStyle`
    :root {
        --backgroundColor: ${props => props.theme.backgroundColor};
        --secondaryBackgroundColor: ${props =>
          props.theme.secondaryBackgroundColor};
        --tertiaryBackgroundColor: ${props =>
          props.theme.tertiaryBackgroundColor};
        --textColor: ${props => props.theme.textColor};
        --secondaryTextColor: ${props => props.theme.secondaryTextColor};
        --disabledTextColor: ${props => props.theme.disabledTextColor};
        --borderColor: ${props => props.theme.borderColor};
        --darkBorderColor: ${props => props.theme.darkBorderColor};
        --sideBarBorderColor: ${props => props.theme.sideBarBorderColor};
        --buttonTextColor: ${props => props.theme.buttonTextColor};
        --buttonPrimaryColor: ${props => props.theme.buttonPrimaryColor};
        --buttonSecondaryColor: ${props => props.theme.buttonSecondaryColor};
        --buttonSecondaryBoxShadow: ${props =>
          props.theme.buttonSecondaryBoxShadow};
        --buttonBorderColor: ${props => props.theme.buttonBorderColor};
        --buttonHoverColor: ${props => props.theme.buttonHoverColor};
        --navShadow: ${props => props.theme.navShadow};
        --focusColor: ${props => props.theme.focusColor};
        --gold: ${props => props.theme.gold};
        --green: ${props => props.theme.green};
        --greenBackground: ${props => props.theme.greenBackground};
        --listIconsColor: ${props => props.theme.listIconsColor};
        --listToolbarBackgroundColor: ${props =>
          props.theme.listToolbarBackgroundColor};
    }
    body {
        background-color: ${props => props.theme.backgroundColor};
    }
`;

function App() {
  const [authComplete, setAuthComplete] = React.useState(false);

  React.useEffect(() => {
    if (localStorage.jwtToken) {
      // Set auth token header auth
      const token = localStorage.jwtToken;
      // Decode token and get admin info and exp
      const decoded = jwtDecode<{
        id: string;
        accessLevel: AccessLevel;
        exp: number;
      }>(token);
      // Set admin and isAuthenticated
      store.dispatch(setCurrentUser(decoded));
      // Check for expired token
      const currentTime = Date.now() / 1000; // to get in milliseconds
      const refreshToken1 = localStorage.refreshToken;
      // Check if refresh token exists
      if (decoded.exp < currentTime && !refreshToken1) {
        if (!refreshToken1) {
          // If not log them out
          store.dispatch(logOutUser());
        }
      } else if (!refreshToken) {
        setAuthToken(token);
        setAuthComplete(true);
      } else {
        // If there is, then request a new token
        store
          .dispatch(refreshToken({ token, refreshToken: refreshToken1 }))
          .then(() => {
            setAuthComplete(true)
          });
      }
    }
    else {
      setAuthComplete(true);
    }
  }, []);

  return authComplete ? (
    <Provider store={store}>
      <ThemeProvider>
        <GlobalStyle />
        <Router>
          <div className={'App wrapper'}>
            <Navbar />
            <div className={'wrapper-content'}>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/login" component={LogIn} />
                <Route exact path="/register" component={RegisterUser} />
                <Route
                  exact
                  path="/reset-password/enter-email"
                  component={EnterEmail}
                />
                <Route
                  exact
                  path="/reset-password/:jwt"
                  component={ResetPassword}
                />
                <PrivateRoute exact path="/users/me" component={UserProfile} />
                {/* List pages */}
                <PrivateRoute exact path="/lists" component={ListSideBarPage} />
                <PrivateRoute
                  exact
                  path="/lists/:id"
                  component={ListSideBarPage}
                />
                {/* Admin pages */}
                <PrivateRoute
                  exact
                  path="/admin/user-manager"
                  component={UserManager}
                  blockAccessTo={AccessLevel.user}
                />
                <PrivateRoute
                  exact
                  path="/admin/activity"
                  component={Activity}
                  blockAccessTo={AccessLevel.user}
                />
                {/* PageNotFound route must stay at bottom */}
                <Route component={PageNotFound} />
              </Switch>
            </div>
          </div>
          <ModalPortal />
        </Router>
      </ThemeProvider>
    </Provider>
  ) : null;
}
export default App;

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { listSlice } from './slices/listSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { listItemSlice } from './slices/listItemSlice';
import { authSlice } from './slices/authSlice';
import { modalSlice } from './slices/modalSlice';
import { userSlice } from './slices/userSlice';
import { friendshipSlice } from './slices/friendshipSlice';

export const store = configureStore({
  reducer: {
    lists: listSlice.reducer,
    listItems: listItemSlice.reducer,
    auth: authSlice.reducer,
    modals: modalSlice.reducer,
    users: userSlice.reducer,
    friendships: friendshipSlice.reducer,
  },
  middleware: getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ListModal } from './ListModal';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { authSelectors } from '../../redux-store/slices/authSlice';
import { IList, ListPrivacyLevels } from '../../types';
import { createList } from '../../redux-store/slices/listSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { DefaultError } from '../../api/endpoint-manager';

type Props = {
  onCancel: () => void;
};

export function AddList(props: Props) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const authUserId = useAppSelector(authSelectors.selectId);

  const [name, setName] = React.useState('');
  const [privacyLevel, setPrivacyLevel] = React.useState(
    ListPrivacyLevels.public
  );
  const [errors, setErrors] = React.useState<DefaultError<IList>>();

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!authUserId) {
      return;
    }

    const newList = {
      name,
      privacyLevel,
      userId: authUserId,
    };

    dispatch(createList({ body: newList }))
      .then(unwrapResult)
      .then((value) => {
        if (value.result) {
          props.onCancel();
          history.push(`/lists/${value.result.data._id}`);
        } else {
          setErrors(value.error.response?.data);
        }
      });
  }

  return (
    <ListModal
      title={'Create New List'}
      onSubmit={onSubmit}
      privacyLevel={privacyLevel}
      name={name}
      onCancel={props.onCancel}
      onPrivacyLevelChange={(e) =>
        setPrivacyLevel(
          e.target.id === ListPrivacyLevels.public
            ? ListPrivacyLevels.public
            : ListPrivacyLevels.private
        )
      }
      onNameChange={(e) => setName(e.target.value)}
      errors={errors}
    />
  );
}

import * as React from 'react';
import { Input } from './new/Input';
import { ComponentProps } from 'react';

type Props = {
  onClose: () => void;
  showOptions: boolean;
  getOptionMarkup: (option: any) => React.ReactNode;
  options: Array<any>;
} & React.ComponentProps<typeof Input>;

export function OptionField({
  showOptions,
  onClose,
  options,
  getOptionMarkup,
  ...inputProps
}: Props) {
  const node = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      // escape key press
      if (e.key === 'Escape') {
        onClose();
      }
    }

    function handleClick(e: MouseEvent) {
      if (!node.current?.contains(e.target as Node)) {
        onClose();
      }
    }

    function removeEventListeners() {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClick);
    }

    if (showOptions) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('click', handleClick);
    } else {
      removeEventListeners();
    }

    return () => {
      removeEventListeners();
    };
  }, [showOptions, onClose]);

  function getOptions() {
    if (!showOptions) {
      return null;
    }
    const optionsMarkup = options.map((option: any) => {
      return getOptionMarkup(option);
    });
    return <div className={'options'}>{optionsMarkup}</div>;
  }

  return (
    <div className={'option-field'} ref={node}>
      <Input {...inputProps} />
      {getOptions()}
    </div>
  );
}

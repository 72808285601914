import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { InteractiveDropDown } from './InteractiveDropDown';
import { IDropDownProps } from './DropDown';

const propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  kind: PropTypes.oneOf(['primary', 'secondary']),
  buttonContent: PropTypes.string,
  dropdownContent: PropTypes.array,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  arrow: PropTypes.bool,
  caret: PropTypes.bool,
  icon: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
};

const defaultProps = {
  size: 'medium',
  kind: 'primary',
  align: 'left',
  arrow: false,
};

interface IButtonDropDownProps
  extends Omit<IDropDownProps, 'dropDownRef' | 'open' | 'onClose'> {
  onButtonClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function ButtonDropDown(props: IButtonDropDownProps) {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <div className={'double-button'}>
      <Button
        onClick={props.onButtonClick}
        kind={props.kind}
        size={props.size}
        icon={props.icon}
        className={'main-button'}
      >
        {props.buttonContent}
      </Button>
      <InteractiveDropDown
        size={props.size}
        caret
        align={'right'}
        kind={'secondary'}
        buttonClassName={'secondary-button'}
        open={open}
        onClose={() => setOpen(false)}
        onDropDownClick={() => setOpen(!open)}
      >
        {props.children}
      </InteractiveDropDown>
    </div>
  );
}

ButtonDropDown.propTypes = propTypes;
ButtonDropDown.defaultProps = defaultProps;
export default ButtonDropDown;

import * as ReactDOM from 'react-dom';
import { useAppSelector } from '../../../redux-store/store';

let modalRoot = document.getElementById('modal-root');

if (!modalRoot) {
  modalRoot = document.createElement('div');
}

export function ModalPortal() {
  const content = useAppSelector((state) => state.modals.content);

  // @ts-ignore
  return ReactDOM.createPortal(content, modalRoot);
}

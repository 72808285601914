import * as React from 'react';
import { UseMenu } from './useMenu';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

type MenuProps = {
  children: React.ReactNode;
  controller: UseMenu;
};

const StyledMenu = styled.ul.attrs({ role: 'menu' })<{ hide: boolean }>`
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};
  position: absolute;
  flex-direction: column;
  align-items: stretch;
  list-style: none;
  margin: 0;
  padding: 4px;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 6px;
  width: 200px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

export function Menu(props: MenuProps) {
  return (
    <StyledMenu
      ref={props.controller.setItemsRef}
      hide={!props.controller.expanded}
      style={props.controller.itemsStyles.popper}
      {...props.controller.itemsAttributes.popper}
    >
      {props.children}
    </StyledMenu>
  );
}

type MenuItemProps = {};

const StyledMenuItem = styled.li.attrs({ role: 'none' })`
  display: flex;
`;

Menu.Item = function MenuItem(props: MenuItemProps) {};

const FocusableMenuItem = styled.button.attrs({ role: 'menuitem' })`
  background-color: transparent;
  border-radius: 4px;
  font-size: 0.9rem;
  flex: 1;
  text-align: start;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.buttonPrimaryColor};
      border-color: ${({ theme }) => theme.buttonPrimaryColor};
      color: white;
    }
  }

  &:focus-visible {
    border-color: ${({ theme }) => theme.buttonPrimaryColor};
  }
`;

type MenuCTAProps = {
  name: string;
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const StyledMenuCTA = styled.button`
  appearance: none;
  border: none;
  cursor: pointer;
`;

Menu.CTA = function MenuCTA(props: MenuCTAProps) {
  return (
    <StyledMenuItem>
      <FocusableMenuItem
        as={StyledMenuCTA}
        onClick={props.onClick}
        name={props.name}
      >
        {props.children}
      </FocusableMenuItem>
    </StyledMenuItem>
  );
};

type MenuLinkProps = {
  to: React.ComponentProps<Link>['to'];
  name: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

Menu.Link = function MenuLink(props: MenuLinkProps) {
  return (
    <StyledMenuItem>
      <FocusableMenuItem as={Link} to={props.to} onClick={props.onClick}>
        {props.children}
      </FocusableMenuItem>
    </StyledMenuItem>
  );
};

Menu.Divider = styled.hr`
  margin: 0.25rem 0.5rem;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

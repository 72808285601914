import { IUser, Theme } from '../types';
import { createDefaultEndpoints, createEndpoint } from './endpoint-manager';

export type CreateUserRequest = {
  body: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    password2: string;
  };
};

export type EditUserRequest = {
  id: string;
  body: {
    firstName?: string;
    lastName?: string;
    email?: string;
    favoriteUsers?: Array<string>;
    theme?: Theme;
    avatarKey?: string;
  };
};

export type UserSearchObject = {
  user: IUser;
  followedByUsers: Array<{ firstName: string; lastName: string }>;
};

export type UserSearchResponse = Array<UserSearchObject>;

export const usersApi = {
  ...createDefaultEndpoints<IUser, CreateUserRequest, EditUserRequest>('users'),
  changeAvatar: createEndpoint<FormData, string>((client, params) =>
    client.post('/api/users/upload-avatar', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ),
  search: createEndpoint<string, UserSearchResponse>((client, query) =>
    client.get(`/api/users/search/${query}`)
  ),
  changePassword: createEndpoint<
    { currentPassword: string; newPassword: string },
    void
  >((client, params) => client.put('/api/users/change-password', params)),
};

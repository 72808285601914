import { Theme } from '../../types';
import { DefaultTheme } from 'styled-components';
import { colors } from './colors';

export const themes: Record<Theme, DefaultTheme> = {
  [Theme.light]: {
    key: Theme.light,
    backgroundColor: colors.white,
    secondaryBackgroundColor: colors.gray['100'],
    tertiaryBackgroundColor: '#e8e9ed',
    textColor: colors.black,
    secondaryTextColor: colors.gray['700'],
    disabledTextColor: '#c7ccd1',
    borderColor: '#dbdbdb',
    darkBorderColor: '#d7d7d9',
    sideBarBorderColor: 'rgb(198, 198, 200)',
    buttonTextColor: '#1b1b1d',
    buttonPrimaryColor: '#438ef8',
    buttonSecondaryColor: 'rgba(0, 0, 0, 0.03)',
    buttonSecondaryBoxShadow:
      '0 1px 0 rgba(27, 31, 35, 0.04), inset 0 1px 0 hsla(0, 0%, 100%, 0.25)',
    buttonBorderColor: '#d7d7d9',
    buttonHoverColor: 'rgba(0, 0, 0, 0.08)',
    navShadow:
      'rgba(6, 6, 7, 0.1) 0 1px 0 0, rgba(6, 6, 7, 0.024) 0 1.5px 0 0, rgba(6, 6, 7, 0.024) 0 2px 0 0',
    focusColor: '#eaf5fc',
    gold: '#ffd700',
    green: '#29a979',
    greenBackground: '#ccff90',
    listBackgroundColors: {
      red: '#f28b82',
      orange: '#fbbc04',
      yellow: '#fff475',
      green: '#ccff90',
      teal: '#a7ffeb',
      lightBlue: '#cbf0f8',
      blue: '#aecbfa',
      purple: '#d7aefb',
      pink: '#fdcfe8',
      brown: '#e6c9a8',
      gray: '#e8eaed',
      default: '#fff',
    },
    listBorderColors: {
      red: '#f28b82',
      orange: '#fbbc04',
      yellow: '#fff475',
      green: '#ccff90',
      teal: '#a7ffeb',
      lightBlue: '#cbf0f8',
      blue: '#aecbfa',
      purple: '#d7aefb',
      pink: '#fdcfe8',
      brown: '#e6c9a8',
      gray: '#e8eaed',
      default: 'rgb(198, 198, 200)',
    },
    listIconsColor: 'rgba(0, 0, 0, 0.18)',
    listToolbarBackgroundColor: 'rgba(0, 0, 0, 0.06)',

    buttons: {
      disabledTextColor: colors.gray['300'],
      disabledBackgroundColor: colors.gray['200'],
      disabledBorderColor: colors.gray['300'],
      textButtonBackgroundColorHovered: 'rgba(0, 0, 0, 0.08)',
      sizes: {
        s: {
          height: '1.75rem',
          padding: '0.5rem',
          fontSize: '0.8rem',
          borderRadius: '5px',
        },
        m: {
          height: '2rem',
          padding: '0.5rem',
          fontSize: '0.9rem',
          borderRadius: '6px',
        },
        l: {
          height: '2.75rem',
          padding: '2rem',
          fontSize: '0.95rem',
          borderRadius: '7px',
        },
      },
      colors: {
        primary: {
          '-1': colors.sky['400'],
          0: colors.sky['500'],
          1: colors.sky['600'],
          boxShadow:
            '0 1px 0 rgba(27, 31, 35, 0.1), inset 0 1px 0 hsla(0, 0%, 100%, 0.03)',
        },
        secondary: {
          '-1': 'rgba(0, 0, 0, 0.03)',
          0: 'rgba(0, 0, 0, 0.08)',
          1: 'rgba(0, 0, 0, 0.13)',
          boxShadow:
            '0 1px 0 rgba(27, 31, 35, 0.04), inset 0 1px 0 hsla(0, 0%, 100%, 0.25)',
        },
        gold: {
          '-1': 'red',
          0: '#d4af37',
          1: 'red',
          boxShadow:
            '0 1px 0 rgba(27, 31, 35, 0.1), inset 0 1px 0 hsla(0, 0%, 100%, 0.03)',
        }
      },
    },

    iconButtons: {
      sizes: {
        s: {
          fontSize: '1.1rem',
          padding: '1.5rem',
        },
        m: {
          fontSize: '1.5rem',
          padding: '0.25rem',
        },
        l: {
          fontSize: '2rem',
          padding: '0.4rem',
        },
      },
    },

    texts: {
      variants: {
        h1: {
          fontSize: '2rem',
          fontWeight: 900,
        },
        h2: {
          fontSize: '1.5rem',
          fontWeight: 700,
        },
        h3: {
          fontSize: '1.25rem',
          fontWeight: 700,
        },
        h4: {
          fontSize: '1rem',
          fontWeight: 700,
        },
        p1: {
          fontSize: '1rem',
          fontWeight: 400,
        },
        p2: {
          fontSize: '0.9rem',
          fontWeight: 400,
        },
      },
    },

    inputs: {
      sizes: {
        s: {
          fontSize: '0.9rem',
          padding: '0.5rem',
        },
        m: {
          fontSize: '1rem',
          padding: '0.85rem',
        },
      },
      variants: {
        1: {
          textColor: colors.black,
          placeholderColor: colors.gray['700'],
          backgroundColor: colors.white,
          borderColor: colors.gray['300'],
        },
        2: {
          textColor: colors.black,
          placeholderColor: colors.gray['700'],
          backgroundColor: colors.gray['100'],
          borderColor: colors.gray['200'],
        },
      },
    },
  },
  [Theme.dark]: {
    key: Theme.dark,
    backgroundColor: '#202125',
    secondaryBackgroundColor: '#000000',
    tertiaryBackgroundColor: '#5e6267',
    textColor: '#fff',
    secondaryTextColor: colors.gray['50'],
    disabledTextColor: '#4f545c',
    borderColor: '#58595b',
    darkBorderColor: '#2c2d30',
    sideBarBorderColor: '#4c4d4f',
    buttonTextColor: '#fff',
    buttonPrimaryColor: '#0d6bdc',
    buttonSecondaryColor: 'rgba(255, 255, 255, 0.11)',
    buttonSecondaryBoxShadow:
      '0 1px 0 rgba(27, 31, 35, 0.04), inset 0 1px 0 rgba(255, 255, 255, 0.05)',
    buttonBorderColor: '#2c2d30',
    buttonHoverColor: 'rgba(255, 255, 255, 0.16)',
    navShadow:
      'rgba(4, 4, 5, 0.2) 0 1px 0 0, rgba(6, 6, 7, 0.05) 0 1.5px 0 0, rgba(4, 4, 5, 0.05) 0 2px 0 0',
    focusColor: '#000073',
    gold: '#d4af37',
    green: '#29a979',
    greenBackground: '#345920',
    listBackgroundColors: {
      red: '#5c2b29',
      orange: '#614a19',
      yellow: '#635d19',
      green: '#345920',
      teal: '#16504b',
      lightBlue: '#2d555e',
      blue: '#1e3a5f',
      purple: '#42275e',
      pink: '#5b2245',
      brown: '#442f19',
      gray: '#3c3f43',
      default: '#202125',
    },
    listBorderColors: {
      red: '#5c2b29',
      orange: '#614a19',
      yellow: '#635d19',
      green: '#345920',
      teal: '#16504b',
      lightBlue: '#2d555e',
      blue: '#1e3a5f',
      purple: '#42275e',
      pink: '#5b2245',
      brown: '#442f19',
      gray: '#3c3f43',
      default: '#4c4d4f',
    },
    listIconsColor: 'rgba(255, 255, 255, 0.51)',
    listToolbarBackgroundColor: 'rgba(255, 255, 255, 0.11)',

    buttons: {
      disabledTextColor: colors.gray['400'],
      disabledBorderColor: colors.gray['400'],
      disabledBackgroundColor: colors.gray['500'],
      textButtonBackgroundColorHovered: 'rgba(255, 255, 255, 0.16)',
      sizes: {
        s: {
          height: '1.75rem',
          padding: '0.8rem',
          fontSize: '0.8rem',
          borderRadius: '5px',
        },
        m: {
          height: '2rem',
          padding: '1rem',
          fontSize: '0.9rem',
          borderRadius: '6px',
        },
        l: {
          height: '3rem',
          padding: '1.6rem',
          fontSize: '0.95rem',
          borderRadius: '7px',
        },
      },
      colors: {
        primary: {
          '-1': colors.sky['500'],
          0: colors.sky['600'],
          1: colors.sky['400'],
          boxShadow:
            '0 1px 0 rgba(27, 31, 35, 0.1), inset 0 1px 0 hsla(0, 0%, 100%, 0.03)',
        },
        secondary: {
          '-1': 'rgba(255, 255, 255, 0.11)',
          0: 'rgba(255, 255, 255, 0.16)',
          1: 'rgba(255, 255, 255, 0.21)',
          boxShadow:
            '0 1px 0 rgba(27, 31, 35, 0.04), inset 0 1px 0 rgba(255, 255, 255, 0.05)',
        },
        gold: {
          '-1': 'red',
          0: '#d4af37',
          1: 'red',
          boxShadow:
            '0 1px 0 rgba(27, 31, 35, 0.1), inset 0 1px 0 hsla(0, 0%, 100%, 0.03)',
        }
      },
    },

    iconButtons: {
      sizes: {
        s: {
          fontSize: '1.1rem',
          padding: '1.5rem',
        },
        m: {
          fontSize: '1.5rem',
          padding: '0.25rem',
        },
        l: {
          fontSize: '2rem',
          padding: '0.4rem',
        },
      },
    },

    texts: {
      variants: {
        h1: {
          fontSize: '2rem',
          fontWeight: 900,
        },
        h2: {
          fontSize: '1.5rem',
          fontWeight: 700,
        },
        h3: {
          fontSize: '1.25rem',
          fontWeight: 700,
        },
        h4: {
          fontSize: '1rem',
          fontWeight: 700,
        },
        p1: {
          fontSize: '1rem',
          fontWeight: 400,
        },
        p2: {
          fontSize: '0.9rem',
          fontWeight: 400,
        },
      },
    },

    inputs: {
      sizes: {
        s: {
          fontSize: '0.9rem',
          padding: '0.5rem',
        },
        m: {
          fontSize: '1rem',
          padding: '0.85rem',
        },
      },
      variants: {
        1: {
          textColor: colors.white,
          placeholderColor: colors.gray['50'],
          backgroundColor: 'transparent',
          borderColor: colors.gray['700'],
        },
        2: {
          textColor: colors.white,
          placeholderColor: colors.gray['50'],
          backgroundColor: colors.black,
          borderColor: colors.gray['600'],
        },
      },
    },
  },
};

export const styleDefaults = {
  // if changing mobile width, make sure to also change in _presets.scss
  mobileWidth: '700px',
  listSidePadding: '3rem',
  listSidePaddingMobile: '0.75rem',
};

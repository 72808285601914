import * as React from 'react';
import { themes } from './themes';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { Theme } from '../../types';
import { usersApi } from '../../api/users';
import { useAppSelector } from '../../redux-store/store';
import { authSelectors } from '../../redux-store/slices/authSlice';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, setTheme] = React.useState<Theme>(Theme.light);
  const authUserId = useAppSelector(authSelectors.selectId);
  const userTheme: Theme = useAppSelector((state) => {
    if (authUserId) {
      const theme = state.users.entities[authUserId]?.theme;
      return theme ?? Theme.light;
    }
    return Theme.light;
  });

  React.useEffect(() => {
    if (authUserId) {
      usersApi.get({ id: authUserId }).then((response) => {
        if (response.error) {
          return;
        }
        setTheme(response.result.data.theme);
      });
    } else {
      setTheme(Theme.light);
    }
  }, [authUserId]);

  React.useEffect(() => {
    setTheme(userTheme);
  }, [userTheme]);

  return (
    <StyledComponentsThemeProvider theme={themes[theme]}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

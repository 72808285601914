import { ListColor } from '../../types';
import styled from 'styled-components';
import { styleDefaults } from '../theme/themes';

interface IListRowProps {
  isDragging?: boolean;
  listColor?: ListColor;
}

export const ListRow = styled.div<IListRowProps>`
  box-sizing: border-box;
  display: flex;
  border: 1px solid transparent;
  padding: 0 3rem;
  background-color: ${(props) =>
    props.theme.listBackgroundColors[props.listColor ?? 'default']};
  box-shadow: ${(props) =>
    props.isDragging
      ? '0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15)'
      : 'initial'};

  @media (max-width: ${styleDefaults.mobileWidth}) {
    padding: 0 0.5rem;
  }

  &:focus-within {
    border-top-color: var(--listIconsColor);
    border-bottom-color: var(--listIconsColor);
  }

  @media (hover: hover) {
    &:hover {
      .list-row-left {
        color: var(--secondaryTextColor);
      }

      .list-row-right {
        .menu-button {
          color: var(--secondaryTextColor);
        }

        .add-link-button {
          visibility: visible;
        }
      }
    }
  }
  &:focus-within {
    .list-row-left {
      color: var(--secondaryTextColor);
    }

    .list-row-right {
      .menu-button {
        color: var(--secondaryTextColor);
      }

      .add-link-button {
        visibility: visible;
      }
    }
  }

  .list-row-left {
    display: flex;
    align-items: start;
    padding: 0.5rem 0.5rem 0.5rem 0;
    padding-right: 0.5rem;
    color: var(--listIconsColor);
  }

  .list-row-middle {
    flex: 1;
    display: flex;
    flex-direction: column;

    .list-row-middle-top {
      flex: 1;
      display: flex;
      align-items: center;

      .list-row-title {
        font-size: 1.1rem;
        font-weight: 400;
        width: 100%;

        &.disabled {
          color: var(--secondaryTextColor);
        }

        .content-editable-field {
          padding: 0.5rem 0;
        }
      }
    }

    .list-row-middle-bottom {
      margin-top: 0.6rem;

      .detail-pill {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        margin-right: 0.5rem;
        font-size: 0.9rem;
        padding: 0.2rem 0.8rem;
        border-radius: 1rem;
        border: 1px solid var(--borderColor);
        box-sizing: border-box;
      }
    }
  }

  .list-row-right {
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    & > button,
    & > .dropdown-wrapper,
    & > .double-button {
      margin-right: 0.5rem;

      &.menu-button {
        padding: 0.1rem;

        @media (hover: hover) {
          transition: all 0s;
          color: transparent;
        }

        &.no-hide {
          color: var(--secondaryTextColor);
        }
      }

      &:last-child {
        margin-right: 0;
      }

      @media (hover: hover) {
        &.add-link-button {
          visibility: hidden;
        }
      }
    }
  }
`;

export const LoadingListRow = styled(ListRow)`
  padding: 1rem;
  margin: 0.8rem 3rem;
  display: block;
  position: relative;
  border-radius: 0.2rem;
  overflow: hidden;
  animation: load 1.5s ease infinite;

  @keyframes load {
    0% {
      background-color: var(--listToolbarBackgroundColor);
    }
    50% {
      background-color: transparent;
    }
    100% {
      background-color: var(--listToolbarBackgroundColor);
    }
  }
`;

import * as React from 'react';
import { ListItemModal } from './ListItemModal';
import { IListItem } from '../../../types';
import { AxiosError } from 'axios';
import { createListItem } from '../../../redux-store/slices/listItemSlice';
import { useAppDispatch } from '../../../redux-store/store';

type Props = {
  onCancel: () => void;
  listId: string;
};

export function AddListItem(props: Props) {
  const dispatch = useAppDispatch();
  const [name, setName] = React.useState('');
  const [link, setLink] = React.useState('');
  const [errors, setErrors] = React.useState<Partial<IListItem>>({});

  function onSuccess() {
    props.onCancel();
  }

  function onFail(err: AxiosError<IListItem>) {
    setErrors(err.response?.data ?? {});
  }

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    const newListItem = {
      name,
      link,
      listId: props.listId,
    };
    dispatch(createListItem({ body: newListItem })).then(onSuccess, onFail);
  }

  return (
    <ListItemModal
      title={'Add Item'}
      onSubmit={onSubmit}
      onCancel={props.onCancel}
      name={name}
      link={link}
      onNameChange={(e) => setName(e.target.value)}
      onLinkChange={(e) => setLink(e.target.value)}
      errors={errors}
    />
  );
}

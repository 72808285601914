import * as React from 'react';
import classnames from 'classnames';

type Props = {
  sideBarContent: React.ReactNode;
  mainContent?: React.ReactNode;
  expanded?: boolean;
  offsetTop?: number;
};

export function SideBar(props: Props) {
  const [skinny, setSkinny] = React.useState(false);
  const [height, setHeight] = React.useState(
    window.innerHeight - (props.offsetTop ?? 0)
  );

  React.useEffect(() => {
    function updateDisplay() {
      if (window.innerWidth < 700 && !skinny) {
        setSkinny(true);
      }

      if (window.innerWidth > 700 && skinny) {
        setSkinny(false);
      }

      if (window.innerHeight - (props.offsetTop ?? 0) !== height) {
        setHeight(window.innerHeight - (props.offsetTop ?? 0));
      }
    }

    // TODO: Throttle this part
    updateDisplay();
    window.addEventListener('resize', updateDisplay);

    return () => window.removeEventListener('resize', updateDisplay);
  }, [skinny, props.offsetTop, height]);

  function getSideBar() {
    if (skinny && !props.expanded) {
      return null;
    }
    return (
      <div className={'side-bar-region'} style={{ height: height + 'px' }}>
        <div className={'side-bar'}>{props.sideBarContent}</div>
      </div>
    );
  }

  function getMainContent() {
    if (!props.expanded) {
      return (
        <div
          className={'main-content-region'}
          style={{ height: height + 'px' }}
        >
          <div className={'main-content'}>{props.mainContent}</div>
        </div>
      );
    }
  }

  return (
    <div
      className={classnames('side-bar-wrapper', {
        expanded: props.expanded,
      })}
    >
      {getSideBar()}
      {getMainContent()}
    </div>
  );
}

import { IListItem } from '../types';
import { createDefaultEndpoints } from './endpoint-manager';

export type CreateListItemRequest = {
  body: {
    name: string;
    listId: string;
    link?: string;
  };
};

export type EditListItemRequest = {
  id: string;
  body: {
    name?: string;
    listId?: string;
    link?: string;
    claimerId?: string | null;
    claimerString?: string | null;
    removedAt?: number;
  };
};

export const listItemsApi = createDefaultEndpoints<
  IListItem,
  CreateListItemRequest,
  EditListItemRequest
>('listItems');

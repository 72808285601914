import * as React from 'react';
import { IUser } from '../../types';
import styled from 'styled-components';
import { getAvatarUrl } from '../../utils/utils';

const AvatarDiv = styled.div<{ size: number }>`
  width: ${(props) => props.size}rem;
  height: ${(props) => props.size}rem;
  border-radius: ${(props) => props.size / 5}rem;
  margin-right: ${(props) => props.size / 3}rem;
  flex: 0 0 auto;
`;

const AvatarImage = styled(AvatarDiv)<{ imageUrl: string }>`
  background-image: url('${(props) => props.imageUrl}');
  background-size: cover;
  background-repeat: no-repeat;
`;

const DefaultAvatar = styled(AvatarDiv)`
  background-color: var(--listIconsColor);
  color: var(--textColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.size / 2}rem;
  font-weight: 900;
`;

interface IUserAvatarProps {
  user: IUser;
  size: number;
}

export function UserAvatar({ size = 3, ...props }: IUserAvatarProps) {
  if (props.user.avatarKey) {
    return (
      <AvatarImage size={size} imageUrl={getAvatarUrl(props.user.avatarKey)} />
    );
  }
  return (
    <DefaultAvatar size={size}>
      {props.user.firstName && props.user.lastName
        ? props.user.firstName[0] + props.user.lastName[0]
        : null}
    </DefaultAvatar>
  );
}

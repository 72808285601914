import * as React from 'react';
import { Link } from 'react-router-dom';
import { ButtonProps, StyledButton } from './Button';

type Props = {
  size: ButtonProps['size'];
  variant: ButtonProps['variant'];
  color: ButtonProps['color'];
} & React.ComponentProps<Link>;

export function ButtonLink({color, ...props}: Props) {
  return <StyledButton as={Link} $color={color} {...props} />;
}

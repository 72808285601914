import * as React from 'react';
import Button from '../ui/Button';
import { PopUpHeader } from './PopUpHeader';
import Modal from '../ui/modal/Modal';
import { useAppDispatch, useAppSelector } from '../../redux-store/store';
import { authSelectors } from '../../redux-store/slices/authSlice';
import { closeModal } from '../../redux-store/slices/modalSlice';
import { useHistory } from 'react-router-dom';
import {
  filterUsers,
  getUser,
  userSelectors,
} from '../../redux-store/slices/userSlice';
import { AccessLevel, IFriendship } from '../../types';
import {
  createFriendship,
  deleteFriendship,
  filterFriendships,
  friendshipsSelectors,
} from '../../redux-store/slices/friendshipSlice';
import { unwrapResult } from '@reduxjs/toolkit';

enum Windows {
  main,
  followers,
  following,
}

type Props = {
  userId: string;
};

export function UserPopUp(props: Props) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const authUserId = useAppSelector(authSelectors.selectId);
  const authUserAccess = useAppSelector(authSelectors.selectAccess);
  const user = useAppSelector((state) =>
    userSelectors.selectById(state, props.userId)
  );
  const friendship = useAppSelector((state) => {
    const id = state.friendships.ids.find(
      (friendshipId) =>
        state.friendships.entities[friendshipId]?.friendId === props.userId &&
        state.friendships.entities[friendshipId]?.userId === authUserId
    );
    return id ? friendshipsSelectors.selectById(state, id) : undefined;
  });
  const following = useAppSelector((state) =>
    friendshipsSelectors.selectFollowingFriendships(state, props.userId)
  );
  const followers = useAppSelector((state) =>
    friendshipsSelectors.selectFollowerFriendships(state, props.userId)
  );
  const users = useAppSelector(userSelectors.selectEntities);
  const friendships = useAppSelector(friendshipsSelectors.selectAll);

  const userIsCurrentUser = user?._id === authUserId;

  const [currentWindow, setCurrentWindow] = React.useState<Windows>();

  React.useEffect(() => {
    dispatch(getUser({ id: props.userId }));
    const friendshipQuery = [
      { userId: props.userId },
      { friendId: props.userId },
    ];
    if (props.userId !== authUserId) {
      authUserId && friendshipQuery.push({ userId: authUserId });
    }
    dispatch(filterFriendships({ body: { $or: friendshipQuery } }))
      .then(unwrapResult)
      .then((res) => {
        if (res.error) {
          return;
        }
        dispatch(
          filterUsers({
            body: {
              _id: res.result.data.flatMap((friendship) => {
                const result = [];
                if (!users[friendship.userId]) {
                  result.push(friendship.userId);
                }
                if (!users[friendship.friendId]) {
                  result.push(friendship.friendId);
                }
                return result;
              }),
            },
          })
        );
      });
  }, [authUserId, dispatch, props.userId]);

  function getUsersName() {
    return `${user?.firstName} ${user?.lastName}`;
  }

  function getFriendName(id: string) {
    return `${users[id]?.firstName} ${users[id]?.lastName}`;
  }

  function unFollow() {
    friendship && dispatch(deleteFriendship({ id: friendship._id }));
  }

  function follow() {
    authUserId &&
      dispatch(
        createFriendship({
          body: {
            userId: authUserId,
            friendId: props.userId,
          },
        })
      );
  }

  function onEditProfileClick() {
    history.push('/users/me');
    dispatch(closeModal());
  }

  function getFollowButton() {
    if (userIsCurrentUser) {
      return (
        <Button kind={'secondary'} onClick={onEditProfileClick} width={'100%'}>
          Edit Profile
        </Button>
      );
    }
    if (friendship) {
      return (
        <Button
          key={'follow'}
          kind={'secondary'}
          onClick={unFollow}
          width={'100%'}
        >
          Unfollow
        </Button>
      );
    } else {
      return (
        <Button key={'follow'} onClick={follow} width={'100%'}>
          Follow
        </Button>
      );
    }
  }

  function getMain() {
    return (
      <>
        <PopUpHeader
          onClose={() => dispatch(closeModal())}
          middleText={
            authUserAccess === AccessLevel.admin ? authUserAccess : undefined
          }
        >
          {getUsersName()}
        </PopUpHeader>
        <div className={'pop-up-body'}>
          <div className={'friends-display'}>
            <Button
              kind={'tertiary'}
              className={'link secondary'}
              onClick={() => setCurrentWindow(Windows.followers)}
            >
              <span className={'count'}>{followers.length}</span> Followers
            </Button>
            <Button
              kind={'tertiary'}
              className={'link secondary'}
              onClick={() => setCurrentWindow(Windows.following)}
            >
              <span className={'count'}>{following.length}</span> Following
            </Button>
          </div>
          <div className={'bottomButtons'}>{getFollowButton()}</div>
        </div>
      </>
    );
  }

  function getFriendsButton(friendId: string, friendship: IFriendship) {
    if (friendId === authUserId) {
      return;
    }
    if (userIsCurrentUser && currentWindow === Windows.followers) {
      return (
        <Button
          size={'small'}
          kind={'secondary'}
          onClick={() => dispatch(deleteFriendship({ id: friendship._id }))}
        >
          Remove
        </Button>
      );
    }
    const existingFriendship = friendships.find(
      (friendship) =>
        friendship.userId === authUserId && friendship.friendId === friendId
    );
    if (existingFriendship) {
      return (
        <Button
          size={'small'}
          kind={'secondary'}
          onClick={() =>
            existingFriendship &&
            dispatch(deleteFriendship({ id: existingFriendship._id }))
          }
        >
          UnFollow
        </Button>
      );
    }

    return (
      <Button
        size={'small'}
        onClick={() =>
          authUserId &&
          dispatch(
            createFriendship({
              body: {
                userId: authUserId,
                friendId: friendId,
              },
            })
          )
        }
      >
        Follow
      </Button>
    );
  }

  function getFriendList(
    friendships: Array<IFriendship>,
    friendshipRole: 'friendId' | 'userId'
  ) {
    if (friendships.length === 0) {
      return (
        <p style={{ color: 'var(--secondaryTextColor)', fontSize: '0.9em' }}>
          Nothing to see here
        </p>
      );
    }
    return (
      <div className={'pop-up-list'}>
        {friendships.map((friendship) => {
          const friendName = getFriendName(friendship[friendshipRole]);
          return (
            <div key={friendship._id} className={'pop-up-list-row'}>
              <div className={'pop-up-list-row-text'} title={friendName}>
                {friendName}
              </div>
              {getFriendsButton(friendship[friendshipRole], friendship)}
            </div>
          );
        })}
      </div>
    );
  }

  function getFollowers() {
    return (
      <>
        <PopUpHeader
          onClose={() => dispatch(closeModal())}
          middleText={getUsersName()}
          onBackClick={() => setCurrentWindow(Windows.main)}
        >
          Followers
        </PopUpHeader>
        <div className={'pop-up-body'}>
          {getFriendList(followers, 'userId')}
        </div>
      </>
    );
  }

  function getFollowing() {
    return (
      <>
        <PopUpHeader
          onClose={() => dispatch(closeModal())}
          middleText={getUsersName()}
          onBackClick={() => setCurrentWindow(Windows.main)}
        >
          Following
        </PopUpHeader>
        <div className={'pop-up-body'}>
          {getFriendList(following, 'friendId')}
        </div>
      </>
    );
  }

  function getCurrentWindowMarkup() {
    switch (currentWindow) {
      case Windows.main:
        return getMain();
      case Windows.followers:
        return getFollowers();
      case Windows.following:
        return getFollowing();
      default:
        return getMain();
    }
  }

  return (
    <Modal
      includeCloseButton={false}
      onCancel={() => dispatch(closeModal())}
      className={'pop-up-modal'}
    >
      <div className={'pop-up'}>{getCurrentWindowMarkup()}</div>
    </Modal>
  );
}

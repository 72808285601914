import * as React from 'react';
import { Box } from './new/Box';
import styled from 'styled-components';

type Props = {
  options: string[];
  value: string;
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
};

export function Radio(props: Props) {
  return (
    <Box>
      {props.options.map((option) => (
        <Box key={option}>
          <RadioOption checked={props.value === option}>
            <Box as={'span'} display={'flex'} alignItems={'center'}>
              <input
                type="radio"
                id={option}
                name={props.name}
                checked={props.value === option}
                onChange={props.onChange}
              />
            </Box>
            <Box margin={{ l: '1rem' }}>{option}</Box>
          </RadioOption>
        </Box>
      ))}
    </Box>
  );
}

const RadioOption = styled.label<{ checked: boolean }>`
  padding: 0.5em 1em;
  border-radius: 6px;
  margin-bottom: 0.5em;
  box-sizing: border-box;
  display: flex;
  text-transform: capitalize;
  font-weight: 700;
  ${({ checked, theme }) =>
    checked && `background-color: ${theme.buttonHoverColor};`}
`;

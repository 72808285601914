import * as React from 'react';
import Dropdown, { IDropDownProps } from './DropDown';

interface IInteractiveDropDownProps extends IDropDownProps {
  onClose(): void;
}

export function InteractiveDropDown({
  onClose,
  ...props
}: IInteractiveDropDownProps) {
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleKeyDown(this: Document, event: KeyboardEvent) {
      // escape key press
      if (event.key === 'Escape') {
        onClose();
      }
    }

    function handleClick(this: Document, event: MouseEvent) {
      if (!dropdownRef.current?.contains(event.target as HTMLElement)) {
        onClose();
      }
    }

    function removeEventListeners() {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClick);
    }

    if (props.open) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('click', handleClick);
    } else {
      removeEventListeners();
    }

    return () => {
      removeEventListeners();
    };
  }, [props.open, onClose]);

  return <Dropdown {...props} dropDownRef={dropdownRef} />;
}

import { IList, ListColor, ListPrivacyLevel } from '../types';
import { createDefaultEndpoints } from './endpoint-manager';

export type CreateListRequest = {
  body: {
    userId: string;
    name: string;
    privacyLevel?: ListPrivacyLevel;
    color?: ListColor;
  };
};

export type EditListRequest = {
  id: string;
  body: {
    name?: string;
    privacyLevel?: ListPrivacyLevel;
    color?: ListColor;
    listItemOrder?: Array<string>;
  };
};

export const listsApi = createDefaultEndpoints<
  IList,
  CreateListRequest,
  EditListRequest
>('lists');

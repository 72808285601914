export interface IFriendship {
  _id: string;
  userId: string;
  friendId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  accessLevel: AccessLevel;
  favoriteUsers?: string[];
  theme: Theme;
  avatarKey?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IAuth {
  userId?: string;
  accessLevel?: AccessLevel;
}

export enum Theme {
  light = 'light',
  dark = 'dark',
}

export enum AccessLevel {
  user = 'User',
  admin = 'Admin',
}

export enum ListPrivacyLevels {
  public = 'public',
  private = 'private',
}

export type ListPrivacyLevel =
  | ListPrivacyLevels.public
  | ListPrivacyLevels.private;

export type ListColor =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'teal'
  | 'lightBlue'
  | 'blue'
  | 'purple'
  | 'pink'
  | 'brown'
  | 'gray'
  | 'default';

export interface IList {
  _id: string;
  userId: string;
  name: string;
  privacyLevel: ListPrivacyLevels.public | ListPrivacyLevels.private;
  listItemOrder: string[];
  color: ListColor;
  createdAt: Date;
  updatedAt: Date;
}

export interface IListItem {
  _id: string;
  listId: string;
  name: string;
  link?: string;
  claimerId?: string;
  claimerString?: string;
  removedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
}

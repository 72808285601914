import * as React from 'react';
import PropTypes from 'prop-types';
import DropDown from './DropDown';

const propTypes = {
  buttonContent: PropTypes.string,
  buttonClassName: PropTypes.string,
  dropdownContent: PropTypes.array,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  arrow: PropTypes.bool,
  caret: PropTypes.bool,
  icon: PropTypes.string,
  kind: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  align: 'left',
  arrow: false,
};

const ControlledDropDown = (props) => {
  const [open, setOpen] = React.useState(false);

  function handleKeyDown(e) {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  }

  function handleClick(e) {
    setOpen(false);
  }

  React.useEffect(() => {
    function removeEventListeners() {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClick);
    }

    if (open) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('click', handleClick);
    } else {
      removeEventListeners();
    }

    return removeEventListeners;
  }, [open]);

  function onDropdownClick(e) {
    e.preventDefault();
    setOpen(!open);
  }

  function onRowClick() {
    setOpen(false);
  }

  return (
    <DropDown
      {...props}
      open={open}
      onDropDownClick={onDropdownClick}
      onRowClick={onRowClick}
    >
      {props.children}
    </DropDown>
  );
};

ControlledDropDown.propTypes = propTypes;
ControlledDropDown.defaultProps = defaultProps;

export default ControlledDropDown;

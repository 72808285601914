import * as React from 'react';
import ContentEditable, {
  ContentEditableEvent,
  Props,
} from 'react-contenteditable';

interface IContentEditableFieldProps
  extends Omit<Props, 'onChange' | 'onBlur'> {
  handleChange: (value: string) => void;
  onEnterKeyPress?: (
    html: string,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  onBlur?: (html: string) => void;
}

// This has to stay a class component based on this issue https://github.com/lovasoa/react-contenteditable/issues/161
export class ContentEditableField extends React.Component<
  IContentEditableFieldProps
> {
  onChange = (event: ContentEditableEvent) => {
    this.props.handleChange(event.target.value);
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (this.props.onEnterKeyPress) {
        this.props.onEnterKeyPress(this.props.html, event);
      }
    }
  };

  onPaste = (event: React.ClipboardEvent) => {
    const clippy = event.clipboardData.getData('text/plain');
    event.stopPropagation();
    event.preventDefault();
    document.execCommand('insertHTML', false, clippy);
  };

  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur(this.props.html);
    }
  };

  render() {
    const { handleChange, onEnterKeyPress, ref, ...rest } = this.props;
    return (
      <ContentEditable
        {...rest}
        className={'content-editable-field'}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        onPaste={this.onPaste}
        onBlur={this.onBlur}
      />
    );
  }
}

import * as React from 'react';
import { IListItem } from '../../../types';
import { AxiosError } from 'axios';
import { LinkModal } from './LinkModal';
import { useAppDispatch } from '../../../redux-store/store';
import { closeModal } from '../../../redux-store/slices/modalSlice';
import { editListItem } from '../../../redux-store/slices/listItemSlice';

type Props = {
  item: IListItem;
};

export function EditLinkModal({ item }: Props) {
  const dispatch = useAppDispatch();
  const [link, setLink] = React.useState(item.link || '');
  const [errors, setErrors] = React.useState<Partial<IListItem>>();

  function onSuccess() {
    dispatch(closeModal());
  }

  function onFail(error: AxiosError) {
    setErrors(error.response?.data);
  }

  function onSubmit(event: React.MouseEvent<HTMLFormElement>) {
    event.preventDefault();
    if (item.link !== link) {
      dispatch(editListItem({ id: item._id, body: { link } })).then(
        onSuccess,
        onFail
      );
    }
  }

  return (
    <LinkModal
      link={link}
      onLinkChange={(event) => setLink(event.target.value)}
      onSubmit={onSubmit}
      onCancel={() => dispatch(closeModal())}
      errors={errors}
    />
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onRowClick: PropTypes.func,
  name: PropTypes.string,
  noDataMessage: PropTypes.string,
  getRowMarkup: PropTypes.func.isRequired,
  droppableId: PropTypes.string,
  onDragEnd: PropTypes.func.isRequired,
  children: PropTypes.node,
};

const defaultProps = {};

const DragAndDropListBody = (props) => {
  function getBodyMarkup() {
    if (isEmpty(props.data) && props.noDataMessage) {
      return <p className={'no-data-message'}>{props.noDataMessage}</p>;
    }
    return props.data.map((row, index) => {
      return (
        <Draggable key={row._id} draggableId={row._id} index={index}>
          {(provided, snapshot) => (
            <div
              {...provided.draggableProps}
              ref={provided.innerRef}
              key={row._id}
              id={row._id}
              onClick={props.onRowClick}
              className={'drag-container'}
            >
              {props.getRowMarkup(
                row,
                snapshot.isDragging,
                provided.dragHandleProps
              )}
            </div>
          )}
        </Draggable>
      );
    });
  }

  return (
    <div className={'list-body'}>
      <DragDropContext onDragEnd={props.onDragEnd}>
        <Droppable droppableId={'list'}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={'list-body'}
            >
              {getBodyMarkup()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {props.children}
    </div>
  );
};

DragAndDropListBody.propTypes = propTypes;
DragAndDropListBody.defaultProps = defaultProps;
export default DragAndDropListBody;

import * as React from 'react';
import { useAppSelector } from '../redux-store/store';
import { authSelectors } from '../redux-store/slices/authSlice';
import { useHistory } from 'react-router-dom';
import { Button } from './ui/new/Button';

export function Landing() {
  const history = useHistory();
  const authUserId = useAppSelector(authSelectors.selectId);
  const isAuthenticated = Boolean(authUserId);

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push('/lists');
    }
  }, [history, isAuthenticated]);

  function routeTo(path: string) {
    history.push(path);
  }

  return (
    <div className="landing-container">
      <div className="landing-content">
        <h1 className="landing-title"><span>Welcome to GiftGo.io</span></h1>
        <div className="copy">
          <p>The wishlist app you've been waiting for!</p>
          <p>
            Easily create and share lists with loved ones to keep track of what
            you want for special occasions. Invite others to view and mark items
            as reserved or purchased. Simplify your gift-giving experience.
          </p>
        </div>
        <div className="enter">
          <Button
            size="l"
            name="sign up"
            variant="filled"
            color="primary"
            onClick={() => routeTo('/register')}
          >
            Sign Up
          </Button>
          <span>or</span>
          <Button
            size="l"
            name="sign up"
            variant="outlined"
            color="secondary"
            onClick={() => routeTo('/login')}
          >
            Log In
          </Button>
        </div>
      </div>
      <div className="copyright">&copy; Copyright Sam Behrens 2020</div>
    </div>
  );
}


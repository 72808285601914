import * as React from 'react';
import { UserPopUp } from './UserPopUp';
import { useAppDispatch } from '../../redux-store/store';
import { openModal } from '../../redux-store/slices/modalSlice';

type Props = {
  userId: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export function UserButton(props: Props) {
  const dispatch = useAppDispatch();

  function onOpen() {
    dispatch(openModal(<UserPopUp userId={props.userId} />));
  }

  const { userId, style, ...buttonProps } = props;

  return (
    <div className={'pop-up-container'} style={style}>
      <button {...buttonProps} onClick={onOpen} />
    </div>
  );
}

import * as React from 'react';
import { AxiosError } from 'axios';
import { sendResetPasswordEmail } from '../../api/auth';
import { Input } from '../ui/new/Input';
import { Box } from '../ui/new/Box';
import { ButtonLink } from '../ui/new/ButtonLink';
import { Icon } from '../ui/new/Icon';
import { Panel } from '../ui/new/Panel';
import { Text } from '../ui/new/Text';
import { Spacer } from '../ui/new/Spacer';
import { Button } from '../ui/new/Button';

export function EnterEmail() {
  const [email, setEmail] = React.useState('');
  const [emailSent, setEmailSent] = React.useState(false);
  const [errors, setErrors] = React.useState<{
    email?: string;
    error?: string;
  }>({});
  const [loading, setLoading] = React.useState(false);

  function onSuccess() {
    setEmailSent(true);
    setLoading(false);
  }

  function onFail(error: AxiosError) {
    setErrors(error.response?.data);
    setLoading(false);
  }

  function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    setLoading(true);
    sendResetPasswordEmail({ email, url: window.location.origin }).then(
      onSuccess,
      onFail
    );
  }

  function getFormMarkup() {
    if (emailSent) {
      return (
        <div style={{ marginTop: '1rem' }}>
          <h2>Success</h2>
          <p style={{ margin: '1rem' }}>
            An email containing the reset password link was sent to{' '}
            <strong>{email}</strong>. It may take up to 5 minutes to arrive.
          </p>
          <p>Contact giftgofeedback@gmail.com for assistance.</p>
        </div>
      );
    }

    return (
      <form onSubmit={onSubmit}>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          label={'Email'}
          autoFocus
          name={'email'}
          type={'email'}
          autoComplete={'username'}
          variant={1}
          size={'m'}
          error={errors.email ?? errors.error}
        />
        <Spacer height={'1rem'} />
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            name={'submit-email-button'}
            type={'submit'}
            disabled={loading}
            variant={'filled'}
            color={'primary'}
            size={'m'}
          >
            Send Email
          </Button>
        </Box>
      </form>
    );
  }

  return (
    <Box maxWidth={'23rem'} margin={{ t: '1rem', h: 'auto' }}>
      <ButtonLink to={'/login'} variant={'outlined'} color={'secondary'} size={'s'}>
        <Icon icon={'keyboard_backspace'} />
        Back
      </ButtonLink>
      <Box as={Panel} margin={{ t: '1rem' }}>
        <Text variant={'h1'}>Reset Password</Text>
        <Spacer height={'1rem'} />
        {getFormMarkup()}
      </Box>
    </Box>
  );
}
